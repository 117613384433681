/* eslint-disable sonarjs/no-duplicate-string */
import dayjs from 'dayjs';
import { formatShortDate } from '../../helpers/date';
import { isIESite, isWowcherBrand } from '../../helpers/pages';
import { VIP_LOGO } from '../constants/images';
import {
  ECOMMERCE_TERMS,
  GIFT_CARD_TERMS,
  LINKS_GLOBAL_WOWCHER,
} from '../links/links';
import {
  BRAND_LIVINGSOCIAL,
  BRAND_WOWCHER,
  GIFTING_OPTIONS as GIFTING_TYPE,
} from '../setup/setup';

const payPalCreditEnabled = JSON.parse(
  process.env.NEXT_PUBLIC_PAYPAL_CREDIT_ENABLED === 'true',
);

const isKlarnaEnabled = JSON.parse(
  process.env.NEXT_PUBLIC_KLARNA_ENABLED === 'true',
);

const isClearpayEnabled = JSON.parse(
  process.env.NEXT_PUBLIC_CLEARPAY_ENABLED === 'true',
);

// get preformatted strings
const getPreformattedStrings = () => {
  const brand = process.env.NEXT_PUBLIC_BRAND;
  const isBrandWowcher = brand === BRAND_WOWCHER;

  return {
    brandTerminology: {
      brand: isBrandWowcher ? 'Wowcher' : 'LivingSocial',
      wowcher_or_voucher: isBrandWowcher ? brand : 'voucher',
      Wowcher_or_voucher: isBrandWowcher ? 'Wowcher' : 'voucher',
      wowchers_or_vouchers: isBrandWowcher ? `${brand}s` : 'vouchers',
      Wowchers_or_vouchers: isBrandWowcher ? 'Wowchers' : 'vouchers',
    },
  };
};

export const PREFORMATTED_STRINGS = getPreformattedStrings();

export const WELLNESS_SEO_TITLE = 'Wellness';
export const WELLNESS_SEO_TEXT =
  'Browse our Spa deals and offers and save up to 80%. Whether you’re spoiling yourself or treating someone else, you’re sure to find that perfect deal in our Spa sale.';

const LINKS_GLOBAL_WOWCHER_ENV = `${
  LINKS_GLOBAL_WOWCHER[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
    process.env.NEXT_PUBLIC_SITE || 'wowcher'
  ]
}`;

const PASSWORDS_SHOULD_MATCH = 'Passwords should match';
const WOWCHER_LIMITED = 'Wowcher Limited';
export const DISMISS = 'dismiss';
export const GO_BACK = 'Go Back';

export const BRAND_NAME = {
  'living-social': 'LivingSocial',
  wowcher: 'Wowcher',
};

// LOCATION
export const COUNTRY_NAME = {
  livingsocial: 'United Kingdom',
  livingsocialie: 'Ireland',
  wowcher: 'United Kingdom',
  wowcherie: 'Ireland',
};

export const CHANGE_LOCATION = 'Change Location';

// MOBILE APP BANNER
export const APP_BANNER_APPLE = 'Apple';
export const APP_BANNER_GOOGLE = 'Google';
export const APP_BANNER_TITLE = {
  livingsocial: 'LivingSocial',
  livingsocialie: 'LivingSocial',
  wowcher: 'Wowcher',
  wowcherie: 'Wowcher',
};
export const APP_BANNER_AUTHOR = 'dmg media ltd';
export const APP_BANNER_PRICE = 'FREE';
export const APP_BANNER_PRICE_SUFFIX_APPLE = ' - On the App Store';
export const APP_BANNER_PRICE_SUFFIX_ANDROID = ' - In Google Play';
export const APP_BANNER_GET = 'Get app';
export const APP_BANNER_OPEN = 'Open app';
export const OPEN_IN_APP = 'Open in App';

// HEADER
export const GIFT_FINDER = 'Gift Finder';
export const MORE = 'more';
export const MORE_CATEGORIES = 'More Categories';
export const MORE_OPTIONS = 'More Options';
export const ALL_CATEGORY_DEALS = (category) => `All ${category} Deals`;

// MOBILE MENU
export const MOBILE_MENU_NAV_HEAD = 'shop by category';
export const MOBILE_MENU_ACCOUNT_HEAD = 'my account';
export const MOBILE_MENU_MORE_HEAD = 'more options';
export const MOBILE_MENU_SUBSCRIBE_HEAD = 'subscribe';
export const MOBILE_MENU_DOWNLOAD = 'download app';
export const VIEW_FOOTER = 'View Footer';

// SEARCH
export const SEARCH_PLACEHOLDER = 'What are you looking for?';
export const SEARCH_INPUT_ARIA_LABEL = {
  livingsocial: 'Search the LivingSocial website',
  livingsocialie: 'Search the LivingSocial website',
  wowcher: 'Search the Wowcher website',
  wowcherie: 'Search the Wowcher website',
};
export const SEARCH_BUTTON_ARIA_LABEL = 'Start Search';
export const NO_RESULTS = 'No results for your search term was found';
export const SUGGESTIONS_TITLE = 'Suggestions';
export const TOP_SEARCHES_TITLE = 'Top Searches';
export const SPONSORED_SEARCH = 'Sponsored';

// FOOTER
export const DOWNLOAD_THE_APP = 'Download the app';
export const APPLE_APP_STORE_ARIA_LABEL = 'Download on the App Store';
export const GOOGLE_PLAY_ARIA_LABEL = 'Get it on Google Play';
export const VIEW_CAREERS = 'View Careers';
export const WORK_WITH_US = 'work with us';
export const FOOTER_DEALS_TITLE = 'Deals';
export const FOOTER_COMPANY_TITLE = 'Company';
export const FOOTER_CUTOMER_SERVICE_TITLE = 'Customer Service';

export const COMPANY_LEGAL_NAME = {
  livingsocial: WOWCHER_LIMITED,
  livingsocialie: 'LivingSocial Europe Limited',
  wowcher: WOWCHER_LIMITED,
  wowcherie: 'Living Social Europe Limited trading as Wowcher',
};

export const COMPANY_TRADING_NAME = {
  livingsocial: 'Wowcher Limited t/a LivingSocial',
  livingsocialie: 'Wowcher Limited t/a LivingSocial',
  wowcher: WOWCHER_LIMITED,
  wowcherie: WOWCHER_LIMITED,
};
export const ALL_RIGHTS_RESERVED = 'All rights reserved';
export const PAYPAL_CREDIT_LEGAL = `<p>Finance provided by PayPal Credit. Terms and conditions apply. Credit subject to status, UK residents only.   
 <p>##COMPANY_TRADING_NAME## is an Appointed Representative of Creditec Limited and is entered on the Financial Services Register (FRN: 740707).
Creditec Limited, a credit broker not a lender is authorised and regulated by the Financial Conduct Authority and is entered on the Financial Services Register under reference number: 971164. Wowcher Limited will receive a commission from Creditec Limited if you subsequently purchase a credit product through the Creditec's platform. More information about this commission can be provided on request.</p>
Wowcher Limited, Registered in England and Wales (Company number 04264984), Registered Office; Wowcher Limited, Unit 1-2, 19A Fortess Grove, London, England, NW5 2HD. Licenced by the Information Commissioners Office, (registration number: Z3213903).</p>
<p>PayPal Credit and PayPal Pay in 3 are trading names of PayPal UK Ltd, Whittaker House, Whittaker Avenue, Richmond-Upon-Thames, Surrey, United Kingdom, TW9 1EH 
PayPal Credit: Terms and conditions apply. Credit subject to status, UK residents only, Wowcher Ltd acts as a broker and offers finance from a restricted range of finance providers. 
Pay in 3 eligibility is subject to status and approval. 18+ UK residents only. Pay in 3 is an unregulated credit agreement. Check if affordable and how you will repay. May make other borrowing more difficult or expensive. See product terms for more details.
We do not receive any commission for introducing customers to PayPal Credit. 
If you would like to know how we handle complaints, please view our <a target="_blank" href="/page/regulated-finance-complaints.html">Regulated Finance Complaints procedure here</a>. You can access our <a target="_blank" href="/page/regulated-finance-disclosure.html">Regulatory Information document here</a>.</p>`;
export const PAYPAL_CREDIT_LEGAL_CHECKOUT_FOOTER = `<p>Finance provided by PayPal Credit. Terms and conditions apply. Credit subject to status, UK residents only.   
 <p>##COMPANY_TRADING_NAME## is an Appointed Representative of Creditec Limited and is entered on the Financial Services Register (FRN: 740707).
Creditec Limited, a credit broker not a lender is authorised and regulated by the Financial Conduct Authority and is entered on the Financial Services Register under reference number: 971164. Wowcher Limited will receive a commission from Creditec Limited if you subsequently purchase a credit product through the Creditec's platform. More information about this commission can be provided on request.</p>
Wowcher Limited, Registered in England and Wales (Company number 04264984), Registered Office; Wowcher Limited, Unit 1-2, 19A Fortess Grove, London, England, NW5 2HD. Licenced by the Information Commissioners Office, (registration number: Z3213903).</p>
<p>PayPal Credit and PayPal Pay in 3 are trading names of PayPal UK Ltd, Whittaker House, Whittaker Avenue, Richmond-Upon-Thames, Surrey, United Kingdom, TW9 1EH 
PayPal Credit: Terms and conditions apply. Credit subject to status, UK residents only, Wowcher Ltd acts as a broker and offers finance from a restricted range of finance providers.PayPal Pay in 3: PayPal Pay in 3 is not regulated by the Financial Conduct Authority.
Pay in 3 eligibility is subject to status and approval. 18+ UK residents only. Pay in 3 is an unregulated credit agreement. Check if affordable and how you will repay. May make other borrowing more difficult or expensive. See product terms for more details.
We do not receive any commission for introducing customers to PayPal Credit. 
If you would like to know how we handle complaints, please view our <a target="_blank" href="/page/regulated-finance-complaints.html">here</a>. To access our regulatory information document, please click <a target="_blank" href="/page/regulated-finance-disclosure.html">here</a>.</p>`;
export const PAYPAL_CREDIT_LEGAL_CHECKOUT = `<p> To view how we handle complaints, click <a target="_blank" href="/page/regulated-finance-complaints.html">here</a>. To access our regulatory information document, please click <a target="_blank" href="/page/regulated-finance-disclosure.html">here</a>.</p>`;
export const PAYPAL_PAY_LATER_FINE_PRINT =
  'Choose <a href="https://www.paypal.com/uk/webapps/mpp/paypal-payin3" target="_blank">Pay in 3 </a> at checkout and enjoy the power to split your purchase into three interest-free payments, with no set up fees or charges.* It’s a great way to help you stay in control of your budget.';
export const PRICES_SUBJECT_TO_CHANGE = 'Prices subject to change';
export const SUBJECT_TO_AVAILABILITY = 'all deals subject to availability';
// HEADER
export const SIGN_UP = 'Sign Up';

// SUBSCRIBE MODAL
export const SUBSCRIBE_CONFIRMATION = `By clicking “CONTINUE” you agree to receive daily emails featuring local service, shopping and travel offers from ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
}. You can change your preferences or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails. View our Privacy & Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">HERE</a>.`;
export const EXPRESS_CHECKOUT_COMPLIANCE = `I understand that, once I redeem a voucher, a separate contract is formed between the merchant and me.`;
export const EXPRESS_CHECKOUT_MOBILE_COMPLAINCE_BOTTOM_TEXT_B = (
  optText,
  theme,
) =>
  `I agree to the <a href="/page/e-commerceterms" data-qa="E-Commerce Terms" target="_blank" style="color: #777777;text-decoration: underline">E-Commerce Terms</a> and by completing this order consent to receive daily emails featuring local, shopping and travel offers.
  You can unsubscribe at any time by clicking on the unsubscribe option in our emails or opt out of marketing emails by clicking <a style="color: #777777;text-decoration: underline" ref="emailToggle1" id="emailToggle1">HERE</a><b>${optText}</b>.`;

export const EXPRESS_CHECKOUT_MOBILE_COMPLAINCE_BOTTOM_TEXT_C = (
  optText,
  theme,
) =>
  `I agree to the <a href="/page/e-commerceterms" data-qa="E-Commerce Terms" target="_blank" style="color: #777777;text-decoration: underline">E-Commerce Terms</a> and by clicking "SUBMIT" or completing 
  this order consent to receive daily emails featuring local, shopping and travel offers.  
  You can unsubscribe at any time by clicking on the unsubscribe option in our emails or opt out of marketing emails by clicking <a style="color: #777777;text-decoration: underline" ref="emailToggle1" id="emailToggle1">HERE</a><b>${optText}</b>.`;

export const EXPRESS_CHECKOUT_DESKTOP_COMPLAINCE_BOTTOM_TEXT_B = (
  optText,
  theme,
) =>
  `I understand that, once I redeem a voucher, a separate contract is formed between the merchant and me. I agree to the <a href="/page/e-commerceterms" data-qa="E-Commerce Terms" target="_blank" style="color: #777777;text-decoration: underline">E-Commerce Terms</a> and by completing this order consent to receive daily emails featuring local, shopping and travel offers.
   You can unsubscribe at any time by clicking on the unsubscribe option in our emails or opt out of marketing emails by clicking <a style="color: #777777;text-decoration:underline;cursor:pointer" ref="emailToggle1" id="emailToggle1">HERE</a><b>${optText}</b>.`;

export const EXPRESS_CHECKOUT_DESKTOP_COMPLAINCE_BOTTOM_TEXT_C = (
  optText,
  theme,
) =>
  `I understand that, once I redeem a voucher, a separate contract is formed between the merchant and me. I agree to the <a href="/page/e-commerceterms" data-qa="E-Commerce Terms" target="_blank" style="color: #777777;text-decoration: underline">E-Commerce Terms</a> and by clicking “SUBMIT” or completing this order consent to receive daily emails featuring local, shopping and travel offers.
   You can unsubscribe at any time by clicking on the unsubscribe option in our emails or opt out of marketing emails by clicking <a style="color: #777777;text-decoration: underline;cursor:pointer" ref="emailToggle1" id="emailToggle1">HERE</a><b>${optText}</b>.`;

export const CONTINUE = `Continue`;
export const CONTINUE_REDEEM_VOUCHERS = 'Continue to redeem vouchers';

// MY ACCOUNT TEXTS
export const LOGIN = 'Login';
export const LOGOUT = 'Logout';
export const LOGIN_REGISTER = 'Not yet signed up?';
export const LOGIN_REGISTER_LINK = 'Register here.';
export const SIGN_IN_WITH_GOOGLE = `Sign in with Google`;
export const SIGN_UP_WITH_GOOGLE = `Sign up with Google`;
export const SIGN_IN_WITH_FACEBOOK = 'Sign in with Facebook';
export const SIGN_IN_WITH_FACEBOOK_ONLY =
  'you previously logged in with Facebook';
export const REQUIRED_FIELD = 'Required field';
export const ENTER_VALID_EMAIL = 'Please enter a valid email';
export const PASSWORD_SIX_CHARACTERS = 'Password must be at least 6 characters';
export const CREATE_PASSWORD_FOR_THIS_ACCOUNT =
  'Create a password for this account';
export const LOGIN_PRIVACY_POLICY = {
  'living-social': `By logging in you agree to continue receiving daily emails featuring local service, shopping and travel offers from LivingSocial. You can change your email preference or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails. View our Privacy &amp; Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">here</a>.`,
  wowcher: `By logging in you agree to continue receiving daily emails featuring local service, shopping and travel offers from Wowcher. You can change your email preferences or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails. View our Privacy &amp; Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">here</a>.`,
};
export const LOGIN_PRIVACY_POLICY_CHECKOUT = (environment, optText) =>
  `We would like to send you&#47;continue to send you daily emails featuring local service, shopping and travel offers from ${
    environment === 'wowcher' ? 'Wowcher' : 'LivingSocial'
  }. You can change your email preferences or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails. Alternatively you are able to opt out of marketing emails by clicking <div ref="emailToggle" id="emailToggle">HERE</div><b>${optText}</b>. View our Privacy &amp; Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">HERE</a>.`;

export const LOGIN_PRIVACY_POLICY_RECURRING = (environment, optText) =>
  `By logging in you agree to continue to receive daily emails featuring local service, shopping and travel offers from ${
    environment === 'wowcher' ? 'Wowcher' : 'LivingSocial'
  }. You can change your preferences and unsubscribe at any time by clicking on the unsubscribe option in the email itself or in the email preference centre. Alternatively you are able to opt out of marketing emails by clicking  <div ref="emailToggle" id="emailToggle">HERE</div><b>${optText}</b>. View our Privacy &amp Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">here</a>.`;

export const FORGOT_YOUR_PASSWORD = 'Forgot your password?';
export const YOUR_EMAIL = 'Your Email';
export const MYACCOUNT_FOOTER_TEXT = {
  livingsocial: `<p class="copyright">© ${dayjs().year()}, Wowcher Limited. All rights reserved.</p>
  ${
    payPalCreditEnabled
      ? `<p class="footer__text">
          Subject to status. 
          Terms and conditions apply. 
          PayPal Credit and PayPal Pay in 3 are trading names of PayPal UK Ltd, Whittaker House, Whittaker Avenue, Richmond-Upon-Thames, Surrey, United Kingdom, TW9 1EH 
          PayPal Credit: Terms and conditions apply. Credit subject to status, UK residents only, Wowcher Ltd acts as a broker and offers finance from a restricted range of finance providers. 
          PayPal Pay in 3: PayPal Pay in 3 is not regulated by the Financial Conduct Authority. Pay in 3 eligibility is subject to status and approval. 
          UK residents only. Pay in 3 is a form of credit, may not be suitable for everyone and use may affect your credit score. See product terms for more details.
        </p>`
      : ''
  }
          <a href="/page/termsofuse">Terms of use</a>,
          <a href="/page/privacypolicy">Privacy &amp; Cookies policy</a>,
          <a href="/page/e-commerceterms">E-commerce terms</a>,
          <a href="/page/internetsafety">Internet safety</a>,
          <a href="/page/uk-modern-slavery-act-transparency-statement">UK Modern Slavery Act</a>.`,
  livingsocialie: `<p class="copyright">© ${dayjs().year()}, Wowcher Limited. All rights reserved.</p>
  ${
    payPalCreditEnabled
      ? `<p class="footer__text">
          Subject to status. 
          Terms and conditions apply. 
          PayPal Credit and PayPal Pay in 3 are trading names of PayPal UK Ltd, Whittaker House, Whittaker Avenue, Richmond-Upon-Thames, Surrey, United Kingdom, TW9 1EH 
          PayPal Credit: Terms and conditions apply. Credit subject to status, UK residents only, Wowcher Ltd acts as a broker and offers finance from a restricted range of finance providers. 
          PayPal Pay in 3: PayPal Pay in 3 is not regulated by the Financial Conduct Authority. Pay in 3 eligibility is subject to status and approval. 
          UK residents only. Pay in 3 is a form of credit, may not be suitable for everyone and use may affect your credit score. See product terms for more details.
        </p>`
      : ''
  }
          <a href="/page/termsofuse">Terms of use</a>,
          <a href="/page/privacypolicy">Privacy &amp; Cookies policy</a>,
          <a href="/page/e-commerceterms">E-commerce terms</a>,
          <a href="/page/internetsafety">Internet safety</a>,
          <a href="/page/uk-modern-slavery-act-transparency-statement">UK Modern Slavery Act</a>.`,
  wowcher: `<p class="copyright">© ${dayjs().year()}, Wowcher  Limited. All rights reserved.</p>
  ${
    payPalCreditEnabled
      ? `<p class="footer__text">
            Subject to status.
            Terms and conditions apply.
            PayPal Credit and PayPal Pay in 3 are trading names of PayPal UK Ltd, Whittaker House, Whittaker Avenue, Richmond-Upon-Thames, Surrey, United Kingdom, TW9 1EH 
            PayPal Credit: Terms and conditions apply. Credit subject to status, UK residents only, Wowcher Ltd acts as a broker and offers finance from a restricted range of finance providers. 
            PayPal Pay in 3: PayPal Pay in 3 is not regulated by the Financial Conduct Authority. Pay in 3 eligibility is subject to status and approval. 
            UK residents only. Pay in 3 is a form of credit, may not be suitable for everyone and use may affect your credit score. See product terms for more details.
        </p>`
      : ''
  }
              <a href="/page/termsofuse">Terms of use</a>,
              <a href="/page/privacypolicy">Privacy &amp; Cookies policy</a>,
              <a href="/page/e-commerceterms">E-commerce terms</a>,
              <a href="/page/internetsafety">Internet safety</a>,
              <a href="/page/uk-modern-slavery-act-transparency-statement">UK Modern Slavery Act</a>.`,
  wowcherie: `<p class="copyright">© ${dayjs().year()}, Wowcher  Limited. All rights reserved.</p>
  ${
    payPalCreditEnabled
      ? `<p class="footer__text">
            Subject to status.
            Terms and conditions apply.
            PayPal Credit and PayPal Pay in 3 are trading names of PayPal UK Ltd, Whittaker House, Whittaker Avenue, Richmond-Upon-Thames, Surrey, United Kingdom, TW9 1EH 
            PayPal Credit: Terms and conditions apply. Credit subject to status, UK residents only, Wowcher Ltd acts as a broker and offers finance from a restricted range of finance providers. 
            PayPal Pay in 3: PayPal Pay in 3 is not regulated by the Financial Conduct Authority. Pay in 3 eligibility is subject to status and approval. 
            UK residents only. Pay in 3 is a form of credit, may not be suitable for everyone and use may affect your credit score. See product terms for more details.
        </p>`
      : ''
  }
              <a href="/page/termsofuse">Terms of use</a>,
              <a href="/page/privacypolicy">Privacy &amp; Cookies policy</a>,
              <a href="/page/e-commerceterms">E-commerce terms</a>,
              <a href="/page/internetsafety">Internet safety</a>,
              <a href="/page/uk-modern-slavery-act-transparency-statement">UK Modern Slavery Act</a>.`,
};

export const DEALS_SUBSCRIPTION = 'Deals subscription';
export const AN_ERROR_OCCURED = 'An error occured';
export const INVALID_CREDENTIALS = 'Invalid email/password. Please try again.';
export const INVALID_GOOGLE_TOKEN_ID = 'Invalid google token id';
export const CHANGE_PASSWORD = 'Change password';
export const UPDATE_PASSWORD = 'update password';
export const UNEXPECTED_ERROR = 'An unexpected error occurred';
export const UNSUCCESSFUL_ERROR = 'Error: Change Unsuccessful';
export const SUCCESSFULLY_UPDATED = 'Password successfully updated';
export const UPDATED_DETAILS =
  'Saved successfully! <br/> Your details were updated.';
export const PERSONAL_INFO = 'Personal Info';
export const EMAIL_PREFERENCES = 'Email Preferences';
export const UPDATE_PREFERENCES = 'Update your preferences';

// SUBSCRIPTIONS
export const SUBSCRIPTIONS_PAGE_TITLE = 'My Subscriptions';
export const SUBSCRIPTIONS_NONE = 'No subscription found';
export const SUBSCRIPTIONS_CANCEL_RENEWAL = 'Cancel Renewal';

// REGISTRATION
export const REGISTER = 'Register';
export const REGISTER_LOGIN = 'Already registered?';
export const REGISTER_LOGIN_LINK = 'Login here.';
export const SOCIAL_MESSAGE = 'It only takes a minute...';
export const FIRST_TIME_BUYER = 'Your details';
export const FIRST_TIME_BUYER_LINK = 'First time buyer?';
export const REGISTER_HERE = 'Register here';
export const DETAIL_TITLE_OR = 'Or';
export const DETAIL_LOGIN = 'Login';
export const DETAIL_LOGIN_HERE = 'Login here';
export const USER_EXISTS =
  'You have already registered with us. Please login to continue.';
export const USER_DOESNT_EXISTS =
  'You have not yet registered with us. Please register to continue.';
export const REGISTER_PRIVACY_POLICY = {
  'living-social': `By creating an account you agree to receive daily emails featuring local service, shopping and travel offers from LivingSocial. You can change your preferences and unsubscribe at any time by clicking on the unsubscribe option in the email itself or in the email preference centre. Our Welcome email includes a link to immediately opt out of any marketing emails. View our Privacy &amp; Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">here</a>.`,
  wowcher: `By creating an account you agree to receive daily emails featuring local service, shopping and travel offers from Wowcher. You can change your preferences and unsubscribe at any time by clicking on the unsubscribe option in the email itself or in the email preference centre. Our Welcome email includes a link to immediately opt out of any marketing emails. View our Privacy &amp; Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">here</a>.`,
};

export const REGISTER_TOP = `Enter your details below, or click to sign up with your Google credentials, to register for an account to receive daily emails featuring local service, shopping and travel offers from ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
}, including a WELCOME DISCOUNT.`;
export const REGISTER_BOTTOM_FIRST = `You can change your preferences or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails. View our Privacy & Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">here</a>.`;

export const REGISTER_PRIVACY_POLICY_RECURRING = (environment, optText) =>
  `We would like to send you daily emails featuring local service, shopping and travel offers from ${
    environment === 'wowcher' ? 'Wowcher' : 'LivingSocial'
  }. You can change your email preferences or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails.  Alternatively you are able to opt out of marketing emails by clicking  <div ref="emailToggle" id="emailToggle">HERE</div><b>${optText}</b>. View our Privacy &amp; Cookie Policy <a href="/page/privacypolicy" data-qa="privacyAndCookiesPolicy" target="_blank">HERE</a>.`;

export const REGISTER_MATCH_PASSWORD = PASSWORDS_SHOULD_MATCH;
export const REGISTER_NOTIFICATION =
  'You can confirm your delivery address once you place your order';

// LOCKED ACCOUNT
export const LOCKED_ACCOUNT = (link) =>
  `Your account has been temporarily locked.
   Please try again later or <a href=${link}><b>reset your password</b></a> to unlock it.`;

// REGISTRATION FORM
export const AUTOCOMPLETE_PROMPT = 'Start typing an address...';
export const FORM_EMAIL = 'Email';
export const FORM_PASSWORD = 'Password';
export const REGISTRATION_FIRST_NAME = 'First Name';
export const REGISTRATION_LAST_NAME = 'Last Name';
export const REGISTRATION_ADDRESS = 'Postcode';
export const LOOKUP_ADDRESS = 'Lookup address';
export const REGISTRATION_ADDRESS_ONE = 'Address Line 1';
export const REGISTRATION_ADDRESS_TWO = 'Address Line 2';
export const REGISTRATION_CITY = 'City';
export const REGISTRATION_EIRCODE = 'Eircode';
export const REGISTRATION_CONFIRM_PASSWORD = 'Confirm Password';
export const REGISTRATION_RULE = 'minimum 6 characters';
export const REGISTER_ENTER_VALID_EMAIL = 'Enter a valid email';
export const REGISTER_ENTER_VALID_POSTCODE = 'Enter a valid postcode';
export const REGISTER_VERIFY_POSTCODE = 'Invalid postcode';
export const REGISTER_POSTCODE_DOESNT_EXIST = `The postcode you entered doesn't exist. Please enter your address manually below`;
export const REGISTER_ADDRESS_DOESNT_EXIST = `The address you entered doesn't exist. Please enter your address manually below`;
export const REGISTER_BUTTON = 'Proceed';
export const REGISTER_LOOKUP_ERROR = `An error occurred looking up your postcode. Please enter your address manually.`;
export const REGISTER_ENTER_MANUALLY = 'Enter address manually?';
export const REGISTER_ENTER_FULL_ADDRESS = 'Enter full address?';
export const REGISTER_TRY_LATER = 'An error occurred. Please try again later.';
export const REGISTER_ERROR_OCCURRED = 'An error occurred.';
export const REGISTER_SUCCESSFULLY = 'You have been successfully registered.';
export const LOOKUP_OR_ENTER_ADDRESS = 'Lookup address or enter one manually';
export const DELETE_ACCOUNT = (email) =>
  `Please log in with the ${email} account if you would like to delete it`;
export const CANCEL_DELETION = 'Cancel the deletion';
export const TITLE_INDEX = {
  'living-social': 'LivingSocial',
  wowcher: 'Wowcher',
};
export const FULL_NAME = 'Name on the account';
export const SORT_CODE = 'Enter 6 digit sort code';
export const ACCOUNT_NUMBER = 'Enter 8 digit account number';
export const NUMBERS_ONLY = 'Please only enter numbers';
export const COMPLIANCE_DISCLAIMER = `This form should only be completed/submitted  by eligible VIPs or former VIPs whom we've reached out to via email.`;
export const COMPLIANCE_CHECKBOX = `By ticking and submitting this form, you acknowledge that none of your existing credit refund has been used.
If eligible, Wowcher will use these details to process your cash refund. Once complete, your bank details will be deleted. Cash refunds can take up to 10 days to be processed`;
export const VIP_OPTED_IN_CHECKBOX = `By ticking and clicking submit, you acknowledge that none of your existing credit refund has been used.
If eligible, Wowcher will re-enroll you in our VIP plan`;
export const CREDIT_UPLIFT = `Why not take an extra 20% credit instead?`;
export const COMPLIANCE_NOTE = `Please note, you have 12 months from the date the credit refund is issued to request a cash refund for your VIP transaction. Should you opt for a cash refund, we’ll require your account name, sort code, and account number.`;
export const COMPLIANCE_SUCCESS = `Form submitted successfully, if eligible, you'll receive your cash refund within 7 days.`;
export const CREDIT_SUCCESS = `Got it, your additional credit will be applied within 3 working days.`;
export const VIP_SUCCESS =
  'Thank you, your VIP subscription will be processed ASAP.';
export const VIP_ERROR = `An unexpected error ocurred, please try again or contact us.`;
export const TITLE_LOGIN = {
  'living-social': 'LivingSocial | Login',
  wowcher: 'Wowcher | Login',
};
export const COMPLIANCE_VIP = `Great! We'll use your credit to keep/put you in VIP. Thank you for being a valued Wowcher VIP Customer!`;
export const TITLE_REGISTER = {
  'living-social': 'LivingSocial | Register',
  wowcher: 'Wowcher | Register',
};
export const MENU = 'Menu';

// These text can be different
export const COUNTDOWN_TEXT = {
  livingsocial: 'deals refresh in*',
  livingsocialie: 'deals refresh in*',
  wowcher: 'Deals Refresh In*',
  wowcherie: 'Deals Refresh In*',
};
export const INITIAL_COUNTDOWN_TIME = '00:00:00:00';

// Claim your gift
export const CLAIM_GIFT_TITLE = 'Claim your gift!';
export const CLAIM_GIFT_TEXT = `
<p class="right-padding">Enter the code from your gift email to claim your e-voucher.</p>
<p>Your e-voucher will then be sent to your registered email address. Follow the instructions on the voucher to redeem it and enjoy!</p>`;
export const CLAIM_GIFT_IMAGE_ALT = {
  livingsocial: 'LivingSocial gift redeem',
  livingsocialie: 'LivingSocial gift redeem',
  wowcher: 'Wowcher gift redeem',
  wowcherie: 'Wowcher gift redeem',
};
export const CLAIM_GIFT_PLACEHOLDER = 'Enter your code here';
export const CLAIM_GIFT_BUTTON = 'Claim gift';
export const CLAIM_GIFT_ERROR = 'Gift code not recognized. Please try again';
export const CLAIM_GIFT_CODE_ACCEPTED = `<p><b>Congratulations!</b> Your gift has been successfully claimed.</p>
  <p>Please check your inbox for your voucher.</p>`;
export const CLAIM_GIFT_CONTINUE_SHOPPING = 'Continue shopping';
export const CLAIM_MY_EXPERIENCE_GIFT_CODE_ACCEPTED = `<p><b>Congratulations!</b> You have successfully claimed. Please click the link below to see your eligible gifts! We have sent you an email with some information.</p>`;
export const CLAIM_MY_EXPERIENCE_GIFT_LINK = 'Select your gift!';

// GIFT CARD
export const GIFT_CARD_TITLE = {
  giftCardTitle: `${BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]} Gift card`,
  redeemTitle: 'Redeem your Gift card',
};

export const WOWCHER_PRODUCTION_SITE = {
  wowcher: 'https://www.wowcher.co.uk',
  wowcherie: 'https://www.wowcher.ie',
};

export const LIVINGSOCIAL_PRODUCTION_SITE = {
  wowcher: 'https://www.livingsocial.co.uk',
  wowcherie: 'https://www.livingsocial.ie',
};

export const GIFT_CARD_TEXT = {
  giftCardTxt: {
    'living-social': `
    <ul>
      <li>LivingSocial gift cards are only redeemable for LivingSocial Wallet Credit to be used online at <a href=${
        LIVINGSOCIAL_PRODUCTION_SITE[process.env.NEXT_PUBLIC_SITE]
      }>${LIVINGSOCIAL_PRODUCTION_SITE[process.env.NEXT_PUBLIC_SITE]}</a>.</li>
      <li>LivingSocial gift cards cannot be exchanged for cash.</li>
      <li>LivingSocial gift cards not redeemed for LivingSocial Wallet Credit within 6 months from the date of purchase, will expire.</li>
      <li>LivingSocial Wallet Credit will expire 12 months after the date of redemption.</li>
      <li>Should the LivingSocial gift card be gifted, it is the purchaser's responsibility to ensure that the recipient of the card is aware of the expiry dates.</li>
      <li>View our full <a href="/page/giftcardterms">Gift card terms and conditions</a></li>
    </ul>`,
    wowcher: `
    <ul>
      <li>Wowcher gift cards are only redeemable for Wowcher Wallet Credit to be used online at <a href=${
        WOWCHER_PRODUCTION_SITE[process.env.NEXT_PUBLIC_SITE]
      }>${WOWCHER_PRODUCTION_SITE[process.env.NEXT_PUBLIC_SITE]}</a>.</li>
      <li>Wowcher gift cards cannot be exchanged for cash.</li>
      <li>Wowcher gift cards not redeemed for Wowcher Wallet Credit within 6 months from the date of purchase, will expire.</li>
      <li>Wowcher Wallet Credit will expire 12 months after the date of redemption.</li>
      <li>Should the Wowcher gift card be gifted, it is the purchaser's responsibility to ensure that the recipient of the card is aware of the expiry dates.</li>
      <li>View our full <a href=${GIFT_CARD_TERMS}>Gift card terms and conditions</a></li>
    </ul>`,
  },
  redeemTxt: {
    'living-social': `
  <p>
    <span class="gift-card__spacer"></span>
    Enter the code from your gift card to redeem for LivingSocial Wallet Credit. The value will appear in your LivingSocial Wallet immediately after redeeming.
  </p>
  <p>N.B. Codes are case sensitive. Please enter your code exactly as it appears on the card.</p>
`,
    wowcher: `
    <p>
      <span class="gift-card__spacer"></span>
      Enter the code from your gift card to redeem for Wowcher Wallet Credit. The value will appear in your Wowcher Wallet immediately after redeeming.
    </p>
    <p>N.B. Codes are case sensitive. Please enter your code exactly as it appears on the card.</p>
  `,
  },
};
// VIP Paywall
export const PAYWALL_HEADER = (environment) =>
  `<p>Want more <span class="paywall-header-wow">WOW</span> from ${
    environment === 'wowcher' ? 'Wowcher' : 'LivingSocial'
  }? </p>`;
export const PAYWALL_SUBTITLE = `<p>Save up to an <span class="paywall-content-subtitle-higlighted">EXTRA 10%</span> with a year of <img
alt="Logo"
className="paywall-content-vip-image"
src=${VIP_LOGO[process.env.NEXT_PUBLIC_BRAND]}
style="margin-top: -10px; transform: rotate(-20deg); height: 70px; padding-left: 0px;"
/><span class="paywall-content-subtitle-higlighted"> for ${
  isIESite() ? '€' : '£'
}4.99</span></p>`;
export const PAYWALL_SUBTITLE_MOBILE = `<p>Save up to an <span class="paywall-content-subtitle-higlighted">EXTRA 10%</span> with a year of <img
alt="Logo"
height=50px;
className="paywall-content-vip-image"
src=${VIP_LOGO[process.env.NEXT_PUBLIC_BRAND]}
style="margin-top: -10px; transform: rotate(-20deg); padding-left: 0px;"
/><span class="paywall-content-subtitle-higlighted">for ${
  isIESite() ? '€' : '£'
}4.99</span></p>`;
export const PAYWALL_DIVIDER_TEXT = `Add more WOW to your savings and receive exclusive access to offers, codes and discounts`;
export const PAYWALL_SAVINGS_BUTTON = 'START SAVING NOW';
export const PAYWALL_HOME_LINK = `I don't want to save more right now`;
export const PAYWALL_LOGIN_TITLE =
  'You have to be logged in to access these savings!';
export const EXPRESS_CHECKOUT_LOGIN_TITLE =
  'Order Complete! Finish logging in to access more offers and your Wowchers.';
export const EXPRESS_CHECKOUT_BASKET_TOTAL = 'Basket Total : ';
export const PAYWALL_SUCCESS_MESSAGE = (environment) =>
  `Congratulations, You’ve become a ${
    environment === 'wowcher' ? 'Wowcher' : 'LivingSocial'
  } VIP!`;
export const VIP_PAYMENT_MODULE_TEXT =
  'Congratulations! You are one step away from making massive savings!';
export const BECOME_A_VIP = 'Become a VIP';

export const GIFT_CARD_PLACEHOLDER = 'Enter your code here';
export const GIFT_CARD_BUTTON = 'REDEEM';
export const GIFT_CARD_RESPONSE = {
  redeem: {
    error: 'An unexpected error occurred, please try again later.',
    success: 'Gift card code has been successfully redeemed!',
  },
  wallet: {
    error: `An error occured fetching your ${process.env.NEXT_PUBLIC_BRAND} details.`,
    success: `You've successfully redeemed your Gift card. Your updated ${process.env.NEXT_PUBLIC_BRAND} balance is below.`,
  },
};
export const GIFT_CARD_WALLET = {
  livingsocial: {
    walletBtn: 'REDEEM ANOTHER GIFT CARD',
    walletContent: {
      altImg: 'LivingSocial wallet',
      text: 'credit for purchases',
      title: 'LivingSocial wallet',
    },
    walletHeader: {
      subTitle: 'view wallet history',
      subUrl: `${LINKS_GLOBAL_WOWCHER_ENV}/myaccount/wallet`,
      title: 'Success!',
    },
  },
  livingsocialie: {
    walletBtn: 'REDEEM ANOTHER GIFT CARD',
    walletContent: {
      altImg: 'LivingSocial wallet',
      text: 'credit for purchases',
      title: 'LivingSocial wallet',
    },
    walletHeader: {
      subTitle: 'view wallet history',
      subUrl: `${LINKS_GLOBAL_WOWCHER_ENV}/myaccount/wallet`,
      title: 'Success!',
    },
  },
  wowcher: {
    walletBtn: 'REDEEM ANOTHER GIFT CARD',
    walletContent: {
      altImg: 'wallet',
      text: 'Credit for purchases',
      title: 'Wowcher Wallet',
    },
    walletHeader: {
      subTitle: 'View wallet history',
      subUrl: `${LINKS_GLOBAL_WOWCHER_ENV}/myaccount/wallet`,
      title: 'Success!',
    },
  },
  wowcherie: {
    walletBtn: 'REDEEM ANOTHER GIFT CARD',
    walletContent: {
      altImg: 'wallet',
      text: 'Credit for purchases',
      title: 'Wowcher Wallet',
    },
    walletHeader: {
      subTitle: 'View wallet history',
      subUrl: `${LINKS_GLOBAL_WOWCHER_ENV}/myaccount/wallet`,
      title: 'Success!',
    },
  },
};

// MINI MENU
// links
const MINI_MENU_DETAILS = `/myaccount/profile`;
// const MINI_MENU_CREDIT = `/myaccount/credit`;
export const MINI_MENU_VOUCHER = `/myaccount/vouchers`;
export const MINI_MENU_SUBSCRIPTIONS = `/myaccount/subscriptions`;
export const MINI_MENU_WALLET = `/myaccount/wallet`;
export const MINI_MENU_VIP_REWARDS = `/myaccount/vip-rewards`;
const MINI_MENU_REWARDS = `/myaccount/rewards`;
const MINI_MENU_REDEEM = `/giftcard/redeem`;
export const MINI_MENU_RAF = `/myaccount/refer-a-friend`;
const MINI_MENU_MYGIFT = `/mygift`;
export const MINI_MENU_NEED_HELP = {
  livingsocial: `https://help.wowcher.co.uk/`,
  livingsocialie: `https://help.wowcher.co.uk/`,
  wowcher: `https://help.wowcher.co.uk/`,
  wowcherie: `https://help.wowcher.co.uk/`,
};
const MINI_MENU_BECOME_MERCHANT = `/page/businessfaq`;
// labels
export const MINI_MENU_LABEL_MERCHANT_AREA = 'Merchant Area';

export const MINI_MENU = {
  livingsocial: [
    { name: 'my vouchers', value: MINI_MENU_VOUCHER },
    { name: 'view account details', value: MINI_MENU_DETAILS },
    { name: 'my subscriptions', value: MINI_MENU_SUBSCRIPTIONS },
    { name: 'Redeem a gift card', value: MINI_MENU_REDEEM },
    // { name: 'claim credit', value: MINI_MENU_CREDIT },
    { name: 'my living social wallet', value: MINI_MENU_WALLET },
    { name: 'claim a gift', value: MINI_MENU_MYGIFT },
  ],
  livingsocialie: [
    { name: 'my vouchers', value: MINI_MENU_VOUCHER },
    { name: 'view account details', value: MINI_MENU_DETAILS },
    { name: 'my subscriptions', value: MINI_MENU_SUBSCRIPTIONS },
    // { name: 'claim credit', value: MINI_MENU_CREDIT },
    { name: 'my living social wallet', value: MINI_MENU_WALLET },
    { name: 'claim a gift', value: MINI_MENU_MYGIFT },
  ],
  wowcher: [
    { name: 'My Wowchers', value: MINI_MENU_VOUCHER },
    { isVipOnly: true, name: 'My Rewards', value: MINI_MENU_VIP_REWARDS },
    { name: 'View account details', value: MINI_MENU_DETAILS },
    { name: 'My Subscriptions', value: MINI_MENU_SUBSCRIPTIONS },
    // { name: 'Claim credit', value: MINI_MENU_CREDIT },
    { name: 'My Wowcher Wallet', value: MINI_MENU_WALLET },
    { name: 'Club Wowcher', value: MINI_MENU_REWARDS },
    { name: 'Redeem a gift card', value: MINI_MENU_REDEEM },
    { name: 'Claim a gift', value: MINI_MENU_MYGIFT },
    { name: 'Become a merchant', value: MINI_MENU_BECOME_MERCHANT },
  ],
  wowcherie: [
    { name: 'My Wowchers', value: MINI_MENU_VOUCHER },
    { name: 'View account details', value: MINI_MENU_DETAILS },
    { name: 'My Subscriptions', value: MINI_MENU_SUBSCRIPTIONS },
    // { name: 'Claim credit', value: MINI_MENU_CREDIT },
    { name: 'My Wowcher Wallet', value: MINI_MENU_WALLET },
    { name: 'Refer a friend', value: MINI_MENU_RAF },
    // { name: 'Club Wowcher', value: MINI_MENU_REWARDS },
    { name: 'Redeem a gift card', value: MINI_MENU_REDEEM },
    { name: 'Claim a gift', value: MINI_MENU_MYGIFT },
    { name: 'Become a merchant', value: MINI_MENU_BECOME_MERCHANT },
  ],
};

// RAF Enabled mobile menu controlled by the env variable for wowcher only
export const RAF_ENABLED_MINI_MENU = {
  wowcher: [
    { name: 'My Wowchers', value: MINI_MENU_VOUCHER },
    { isVipOnly: true, name: 'My Rewards', value: MINI_MENU_VIP_REWARDS },
    { name: 'View account details', value: MINI_MENU_DETAILS },
    { name: 'My Subscriptions', value: MINI_MENU_SUBSCRIPTIONS },
    { name: 'My Wowcher Wallet', value: MINI_MENU_WALLET },
    { name: 'Club Wowcher', value: MINI_MENU_REWARDS },
    { name: 'Refer a friend', value: MINI_MENU_RAF },
    { name: 'Redeem a gift card', value: MINI_MENU_REDEEM },
    { name: 'Claim a gift', value: MINI_MENU_MYGIFT },
    { name: 'Become a merchant', value: MINI_MENU_BECOME_MERCHANT },
  ],
  wowcherie: [
    { name: 'My Wowchers', value: MINI_MENU_VOUCHER },
    { name: 'View account details', value: MINI_MENU_DETAILS },
    { name: 'My Subscriptions', value: MINI_MENU_SUBSCRIPTIONS },
    { name: 'My Wowcher Wallet', value: MINI_MENU_WALLET },
    { name: 'Refer a friend', value: MINI_MENU_RAF },
    { name: 'Redeem a gift card', value: MINI_MENU_REDEEM },
    { name: 'Claim a gift', value: MINI_MENU_MYGIFT },
    { name: 'Become a merchant', value: MINI_MENU_BECOME_MERCHANT },
  ],
};

// CHECKOUT
export const TITLE_CHECKOUT = {
  'living-social': 'LivingSocial | Checkout',
  wowcher: 'Wowcher | Checkout',
};
export const ADVISTORY_DELIVERY_OPTION =
  'Based on your billing postcode, we’ve selected the required option for delivery to this address.';
export const WARNING_POSTAGE_OPTION = `Click and Collect orders will need to be picked up from our collection point in Derby. <br>Address: Wowcher, Pentagon House, Sir Frank Whittle Road, Derby DE21 4XA. <br>This is our <b>only</b> collection point. Collections are available between 9am to 5pm, Monday to Friday excluding bank holidays.`;
export const EXCLUDED_TERRITORIES_POSTCODE =
  'Based on your billing postcode, we won’t be able to deliver to your location if you redeem for delivery to this address.';
export const YOUR_BASKET = 'Your Basket';
export const EXPRESS_CHECKOUT = 'Express Checkout';
export const YOUR_BASKET_IS_EMPTY = 'Your basket is empty!';
export const BROWSE_GREAT_DEALS_AND =
  'Browse some of our great deals and add them to your basket today.';
export const BROWSE_GREAT_DEALS_OR = 'Browse some of our great deals or';
export const TO_SEE_SAVED_ITEMS = 'to see saved items.';
export const CONTINUE_SHOPPING = 'Continue Shopping';
export const TIME_REMAINING = 'Availability re-checked in';
export const RESERVED_MESSAGE =
  'Products reserved in your basket at this price for';
export const RESERVED_END_MESSAGE = 'Products no longer reserved';
export const REFRESHING = 'Refreshing';
export const PRICES_CONFIRMED = 'Availability confirmed!';
export const NO_QUIBBLE = {
  livingsocial: 'No quibble 14 day refund guarantee on unredeemed vouchers',
  livingsocialie: 'No quibble 14 day refund guarantee on unredeemed vouchers',
  wowcher: 'No quibble 14 day refund guarantee on unredeemed Wowchers',
  wowcherie: 'No quibble 14 day refund guarantee on unredeemed Wowchers',
};
export const CHANGE_YOUR_MIND =
  'Change your mind? You can redeem this towards any other deal on site';
export const CUE_ATOL_PROTECTED =
  'ATOL Protected - Financial protection through the UK ATOL Scheme';
export const CUE_ATOL_INSTANT = 'Instant Booking - No redemption required';
export const ATOL_LOGO_ALT = 'Atol logo';
export const ORDER_SUMMARY = 'Order Summary';
export const PAYMENT = 'Payment';
export const YOUR_DETAILS = 'Your Details';
export const SAVE = 'Save';
export const DEPOSIT = 'Deposit';
export const STATUS = 'Status';
export const NOW = 'Now';
export const WAS = 'was';
export const ONLY = 'Only';
export const JUST = 'Just';
export const GET = 'GET';
export const QUANTITY = 'Quantity';
export const SUBTOTAL = 'Subtotal';
export const TOTAL = 'Total';
export const FREE = 'FREE';
export const DELETE = 'Delete';
export const SELECT_QUANTITY = 'Select Quantity';
export const GIFTING_OPTIONS = 'Gifting Options';
export const SHOW_GIFTING_INFO = 'Show gifting information';
export const EGIFTING_INCLUDES = {
  'living-social': 'e-gifting',
  wowcher: 'e-Gifting',
};
export const PINK_IMMEDIATE_DELIVERY = {
  'living-social': 'immediate delivery',
  wowcher: 'Immediate delivery',
};
export const VIA_EMAIL = 'via email';
export const GIFTING_WOWCHER = {
  livingsocial: 'gifting @ livingsocial',
  livingsocialie: 'gifting @ livingsocial',
  wowcher: 'Gifting @ Wowcher',
  wowcherie: 'Gifting @ Wowcher',
};
export const EGIFTING_INCLUDES_BULLETS = {
  livingsocial: [
    'personalised message',
    'a link for your recipient to claim their gift',
    'pdf of your chosen gift with highlights and images for your recipient',
  ],
  livingsocialie: [
    'personalised message',
    'a link for your recipient to claim their gift',
    'pdf of your chosen gift with highlights and images for your recipient',
  ],
  wowcher: [
    'Personalised message',
    'A link for your recipient to claim their gift',
    'PDF of your chosen gift with highlights and images for your recipient',
  ],
  wowcherie: [
    'Personalised message',
    'A link for your recipient to claim their gift',
    'PDF of your chosen gift with highlights and images for your recipient',
  ],
};

export const GIFT_PACK_INCLUDES = {
  'living-social': 'gift pack',
  wowcher: 'Gift Pack',
};

export const GIFT_PACK_INCLUDES_BULLETS = {
  livingsocial: [
    { label: 'sleek, blue, presentation wallet' },
    { label: 'personalised message' },
    { label: 'highlights and images from your chosen gift' },
    {
      label: 'delivery:',
      subItems: [
        'standard delivery: within 2-3 working days',
        'next day delivery: orders placed before 2pm are sent by royal mail tracked 24 service which includes saturday deliveries (orders placed after 2pm on fridays will be dispatched on the next working day)',
      ],
    },
  ],
  livingsocialie: [
    { label: 'sleek, blue, presentation wallet' },
    { label: 'personalised message' },
    { label: 'highlights and images from your chosen gift' },
    {
      label: 'delivery:',
      subItems: ['standard delivery: within 2-3 working days'],
    },
  ],
  wowcher: [
    { label: 'Sleek, pink, presentation wallet' },
    { label: 'Personalised message' },
    { label: 'Highlights and images from your chosen gift' },
    {
      label: 'Delivery:',
      subItems: [
        'Standard Delivery: Within 2-3 working days',
        'Next Day Delivery: Orders placed before 2pm are sent by Royal Mail Tracked 24 service which includes Saturday deliveries (Orders placed after 2pm on Fridays will be dispatched on the next working day)',
      ],
    },
  ],
  wowcherie: [
    { label: 'Sleek, pink, presentation wallet' },
    { label: 'Personalised message' },
    { label: 'Highlights and images from your chosen gift' },
    {
      label: 'Delivery:',
      subItems: ['Standard Delivery: Within 2-3 working days'],
    },
  ],
};

export const GIFT_WRAP_INCLUDES = 'Gift Wrap';
export const GIFT_WRAP_INCLUDES_BULLETS = [
  'Your present will arrive in a Christmas themed bag at your recipients address',
  'Every bag will have a ribbon closure',
  'We will ensure the best sized bag is chosen for your gift',
  'Each sized bag has a different Christmas design',
  'You can include a personalised message',
  'Your message will be printed and attached to the bag',
];
export const EGIFT_ALT = 'eGift example';
export const GIFT_PACK_ALT = 'Gift pack example';
export const GIFT_WRAP_ALT = 'Gift wrap example';
export const BASED_ON_SHARING = 'This deal is based on 2 people sharing';
export const GIVE_AS_A_GIFT = 'Give as a gift';
export const BUY_AS_A_GIFT = 'buy as a gift';
export const DATE = 'Date';
export const CHECK_IN = 'Check In';
export const CHECK_OUT = 'Check Out';
export const NIGHTS = 'Nights';
export const ADD_DATES = 'Select date';
export const AVAILABLE_DATES = (dates) => `${dates} dates available!`;
export const DEPOSIT_PAY_FULL = 'Pay Full Amount:';
export const DEPOSIT_PAY_DEPOSIT = 'Pay Deposit:';
export const DEPOSIT_PAY_TO_MERCHANT = 'to be paid to merchant';
export const GET_IT_BY = 'get it by';
export const MUST_REDEEM_TODAY =
  'Redeem today for delivery by specified date. Delivery price per voucher.';
export const REDEEM_BCOR_MESSAGE =
  "*Don't forget to Redeem within 48 hours to secure your desired date!";
export const REDEEM_BCOR_GIFT_MESSAGE =
  '*Ensure your giftee redeems ASAP in order to secure their desired date!';
export const PLEASE_CHOOSE_ONE_PRODUCT = 'Please choose at least one product';
export const DEAL_DAILY_CAP_REACHED =
  'The maximum quantity of this deal that you can buy today is ###dealcap###';
export const GIFT_PACK_UPGRADE = (extra) =>
  `Upgrade: Additional ${extra} required at checkout`;
export const DEAL_LIMITS_REACHED =
  'You already have the maximum quantity of this deal in your basket. Please proceed to the checkout or edit your basket.';
export const PROCEED = 'Proceed';
export const DEAL_BASKET_MAX_PRODUCT =
  "You've already purchased the max quantity for these products. <strong>They will be removed at purchase.</strong>";
export const DEAL_BASKET_OVERLIMIT_PRODUCT_MESSAGE =
  'You have reached the max quantity for these products in your basket. Please remove the products and add the allowed amount again.';
export const DEAL_BASKET_OVERLIMIT_PRODUCT = `${DEAL_BASKET_OVERLIMIT_PRODUCT_MESSAGE} <strong> They will be removed at purchase.</strong>`;
export const VIP_SUBCRIBE = `One year of ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
} VIP for just`;
export const VIP_EXCLUSIVE = `A year of ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
} VIP has been added to your basket as you have chosen to benefit from an exclusive deal`;
export const VIP_SUBCRIBE_EXCLUSIVE = `A year of ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
} VIP has been added to your basket as you have chosen to benefit from an exclusive deal`;
export const VIP_INFO_TITLE = `Become a ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
} VIP and you will receive:`;
export const VIP_INFO_INCLUDES_BULLET = [
  "Extra discount on eligible deals saving you more! Don't miss out!",
  'Qualify for complementary and exclusive products from our top merchants!',
  'Free entry into all of our competitions. You could be in with a chance to win prizes of up to £20,000!',
  'Priority customer service - guaranteed 24 hour response from our Customer Service Team',
];
export const VIP_INFO_BULLET_ONLY_WOWCHER_UK = [
  'Unlock unique rewards from top brands like Audible, Ancestry, and more!',
];
export const VIP_INFO_TEXT = `And we promise to refund you if ${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
}  VIP isn’t for you - we offer a 14 day no quibble refund!`;
export const VIP_TC = 'View VIP T&Cs';
export const VIP_BELOW_CTA =
  'You may purchase either with or without VIP. If you select the option with VIP, the price of your basket will be reduced so that the total price you pay will be no more than the total price without VIP.';

// VIP HUB
export const VIP_HUB = 'vip-hub';
export const VIP_CONTACT_TEXT = 'CONTACT US';
export const VIP_CONTACT_TITLE = 'DO YOU HAVE A QUESTION FOR US?';
export const VIP_CONTACT_DESC =
  'You can ask us anything and we are happy to help, with our VIP priority customer service function:';
export const VIP_HUB_BANNER_TITLE = 'Your Exclusive Deals';
export const VIP_HUB_BANNER_DESC =
  'Handpicked exclusive deals with limited availability from our top merchants, especially for you !';
export const VIP_WITHOUT_UNAVAILABLE =
  'This option is unavailable as the deal you have selected requires a VIP membership';
export const VIP_SEARCH_ENABLED = 'Now showing results from all VIP deals';
export const VIP_SEARCH_PLACEHOLDER = 'Search our exclusive Vip collection!';
export const VIP_SEARCH_DISABLED = 'Now showing results from all deals';
export const VIP_ENABLED_POPUP_TITLE = "Can't find what you're looking for?";
export const VIP_ENABLED_POPUP_DESC =
  'Disabling will show a wider range of great deals!';
export const VIP_DISABLED_POPUP_TITLE = 'Switch on to save!';
export const VIP_DISABLED_POPUP_DESC =
  'Enabling will show exclusive VIP deals and prices';

// CHECKOUT - Payment
export const CARD_BUTTON_TEXT = 'Pay with Card';
export const PAYPAL_BUTTON_TEXT = 'Pay with PayPal';
export const GOOGLE_BUTTON_TEXT = 'Pay with Google Pay';
export const APPLE_BUTTON_TEXT = 'Pay with';
export const CARD_BUTTON_IMG_TEXT = 'Credit Card';
export const PAYPAL_BUTTON_IMG_TEXT = 'PayPal';
export const GOOGLE_BUTTON_IMG_TEXT = 'Google Pay';
export const APPLE_BUTTON_IMG_TEXT = 'Apple Pay';
export const PAYPAL_CREDIT_BUTTON_IMG_TEXT = 'PayPal Credit';
export const PAYPAL_PAY_LATER_BUTTON_IMG_TEXT = 'PayPal Pay Later';
export const CLEARPAY_BUTTON_IMG_TEXT = 'Klarna';
export const CLEARPAY_MARKETING_BADGE_TEXT = 'Klarna Marketing Badge';
export const CLEARPAY_DISABLED_MESSAGE =
  'Please complete the Your Details section before proceeding.';
export const KLARNA_BUTTON_IMG_TEXT = 'Klarna';
export const KLARNA_MARKETING_BADGE_TEXT = 'Klarna Marketing Badge';
export const KLARNA_HINT = '18+, T&C apply, Credit subject to status.';
export const KLARNA_DISABLED_MESSAGE =
  'Please complete the Your Details section before proceeding.';
export const PAYPAL_FORM_MESSAGE =
  'You will be redirected to PayPal to complete the payment.';
export const GOOGLE_FORM_MESSAGE =
  "Tap the 'Google Pay' button below and confirm your purchase.";
export const APPLE_FORM_MESSAGE =
  "Tap the 'Apple Pay' button below and confirm your purchase with Touch ID or Face ID on your Apple device.";
export const I_AGREE = 'I agree to the';
export const BASKET_SUMMARY_TOTAL = 'Basket Total';
export const BASKET_SUMMARY_ITEMS = 'items';
export const PAYMENT_LOGIN_MESSAGE = 'Login or register to complete purchase.';
export const PAYMENT_ADDRESS_MESSAGE =
  'Add your full address to complete purchase.';
export const PAYMENT_ADDRESS_MESSAGE_POSTCODE =
  'Add your postcode to complete purchase.';
export const PAYMENT_NO_PURCHASABLE_MESSAGE =
  'You have no purchasable products in your basket.';
export const PAYMENT_PASSENGER_DETAILS_MESSAGE =
  'Please provide valid passenger details to complete purchase.';
export const BUTTON_PLACE_ORDER = {
  livingsocial: 'place your order',
  livingsocialie: 'place your order',
  wowcher: 'PLACE YOUR ORDER',
  wowcherie: 'PLACE YOUR ORDER',
};
export const VIP_RADIO_HEADING = 'purchase options';
export const BUTTON_BUY_WITH_VIP = 'place your order + vip';
export const BUTTON_GO_TO_PAYMENT = 'GO TO PAYMENT';
export const BUTTON_PAYPAL_CREDIT = 'Continue with';
export const BUTTON_APPLE = 'Pay with';
export const CARD_TOGGLE_ANOTHER_PRE = 'Or ';
export const CARD_TOGGLE_ANOTHER_LINK = 'use another card';
export const CARD_TOGGLE_ANOTHER_DATA_QA = 'changeCard';
export const CARD_TOGGLE_SAVED_PRE = 'You can also ';
export const CARD_TOGGLE_SAVED_LINK = 'use your saved card';
export const CARD_TOGGLE_SAVED_DATA_QA = 'useSavedCard';
export const CARD_TOGGLE_SEE_ALL_CARDS = 'see all your cards';
export const CARD_FLEXFORM_CONTAINER_ID = 'cardNumber-container';
export const CARD_INPUT_NAME = 'cardNumber';
export const CARD_INPUT_DATA_QA = 'cardNumber';
export const CARD_INPUT_PLACEHOLDER = 'Long card number';
export const CARD_INPUT_ARIA_LABEL = 'Long card number';
export const CARD_ERROR_EXPIRED = 'Card Expired';
export const CARD_ERROR_REQUIRED = 'Sorry, Credit Card number is required';
export const CARD_ERROR_INVALID = "Hmm. That card number doesn't look right";
export const CARD_ERROR_DATE_NOT_VALID = 'This expiry date is not valid';
export const CARD_ERROR_TYPES =
  'Sorry, we currently only accept Visa and MasterCard';
export const CVV_INPUT_NAME = 'cardCvv';
export const CVV_INPUT_DATA_QA = 'securityCode';
export const CVV_INPUT_PLACEHOLDER = 'Security code';
export const CVV_INPUT_ARIA_LABEL = 'Security code';
export const CVV_INFO_ERROR =
  'Please enter your security code (last 3 digits on the\n' +
  '                        back of the card)';
export const CVV_ERROR_REQUIRED = 'Security code is required';
export const CVV_ERROR_LENGTH = 'Security code requires ##numdigits## digits';
export const CVV_ERROR_INVALID = 'Please enter a valid security code';
export const MONTH_INPUT_NAME = 'cardExpiryMonth';
export const MONTH_INPUT_DATA_QA = 'expiryMonth';
export const MONTH_INPUT_ARIA_LABEL = 'Card Expiry Month';
export const MONTH_ERROR_REQUIRED = 'Month is required';
export const YEAR_INPUT_NAME = 'cardExpiryYear';
export const YEAR_INPUT_DATA_QA = 'expiryYear';
export const YEAR_INPUT_ARIA_LABEL = 'Card Expiry Year';
export const YEAR_ERROR_REQUIRED = 'Year is required';
export const DEFAULT_PAYMENT_ERROR =
  'There was a problem with your payment and your order has not been placed. Please check your details and try again. If the issue persists please contact us for assistance.';
export const ERROR_MESSAGE_BRAINTREE =
  'We had a problem completing your request, please continue your purchase or try again later';
export const ERROR_MESSAGE_KLARNA =
  'We had a problem completing your Klarna payment, please try again or use a different payment method';
export const ERROR_MESSAGE_CLEARPAY =
  'We had a problem completing your Clearpay payment, please try again or use a different payment method';
export const LIABILITY_ERROR = `Unfortunately, your payment did not go through. Please try again or select a different payment method`;
export const POSTAL_CODE_REQUIRED = 'Postal code is required';

// CHECKOUT - Your details
export const DETAILS_BILLIND_ADDRESS = 'Billing address';
export const DETAILS_CHANGE_ADDRESS = 'Change address';
export const DETAILS_CHANGE_POSTCODE = 'Change postcode';
export const DETAILS_UPDATE_ADDRESS = 'Update address';
export const DETAILS_UPDATE_POSTCODE = 'Update postcode';
export const DETAILS_ADD_ADDRESS = 'Add address';
export const DETAILS_ADD_POSTCODE = 'Add postcode';
export const BUTTON_CANCEL = 'Cancel';
export const DETAILS_ADDRESS_IS_UPDATED = `Billing address updated successfully!`;
export const DETAILS_ADDRESS_UPDATE_ERROR = `Billing address wasn't updated`;
export const VIP_PAY_BUTTON_MESSAGE = 'Please select one of the options above.';
export const VIP_PAY_BUTTON_MESSAGE_EXCLUSIVE =
  'Please select the option above.';
export const VIP_PAY_BUTTON_MESSAGE_SELECTED =
  'Use the button below to complete this purchase.';
export const VIP_PAY_EXCLUSIVE_DEAL_MESSAGE =
  'To benefit from your VIP exclusive deal, an active VIP membership is required.';

// EXTEND VOUCHERS
export const EXTEND_DROP_DOWN_TITLE = 'Voucher Expiring Soon?';
export const EXTEND_DROP_DOWN_SUB_TITLE =
  'Need more time? Extend your voucher for more flexibility!';
export const EXTEND_SUB_TITLE = `We've got you covered. Extend your voucher's validity by up to 3 months for even more flexibility!`;
export const EXTEND_ELIGIBLE = 'Eligible Vouchers';
export const EXTEND_ERROR_MISSING_ID = 'Sorry, a product order id is required.';
export const EXTEND_ERROR_NONE_ELIGIBLE =
  'Sorry, this order has no vouchers eligible for extension.';
export const EXTEND_TOTAL = 'Total';
export const EXTEND_LOGIN_MESSAGE = {
  [BRAND_LIVINGSOCIAL]:
    'Log into your LivingSocial account to find out which of your amazing deals are eligible for extension.',
  [BRAND_WOWCHER]:
    'Log into your Wowcher account to find out which of your amazing deals are eligible for extension.',
};
export const EXTEND_BUTTON = 'Extend';
export const EXTEND_NOW_BUTTON = 'Extend now';

// MY VOUCHERS
export const WALLET_NAME = {
  [BRAND_LIVINGSOCIAL]: 'LivingSocial Wallet',
  [BRAND_WOWCHER]: 'Wowcher Wallet',
};
export const PAGE_TITLE_MY_VOUCHERS = {
  [BRAND_LIVINGSOCIAL]: 'My Vouchers | LivingSocial',
  [BRAND_WOWCHER]: 'My Wowchers | Wowcher',
};
export const PAGE_TITLE_VIP_REWARDS = 'My Rewards';
export const MY_WOWCHERS_TITLE = {
  [BRAND_LIVINGSOCIAL]: 'My Vouchers',
  [BRAND_WOWCHER]: 'My Wowchers',
};
export const DEPOSIT_TRANSACTION = {
  [BRAND_LIVINGSOCIAL]:
    'Deposit transaction only, view voucher for remaining balance',
  [BRAND_WOWCHER]:
    'Deposit transaction only, view Wowcher for remaining balance',
};
export const RAF_TITLE = 'Refer A Friend';
export const DEPOSIT_NON_TRAVEL =
  'Deposit Paid. No further action required until we contact you.';
export const ORDER_COMPLETE = '✔ Order complete!';
export const REDEEM_IT = 'REDEEM IT!';
export const REDEEM_IN_APP = 'REDEEM IN APP';
export const HOW_TO_REDEEM_WOWCHER = 'How do I redeem my Wowcher?';
export const HOW_TO_REDEEM_LIVING_SOCIAL = 'How do I redeem my Voucher?';
export const HOW_TO_REDEEM_WOWCHER_DESKTOP =
  'Scan this QR code to REDEEM your voucher';
export const GET_WOWCHER = {
  [BRAND_LIVINGSOCIAL]: 'Get Voucher!',
  [BRAND_WOWCHER]: 'Get Wowcher!',
};
export const VIEW_WOWCHER = {
  [BRAND_LIVINGSOCIAL]: 'View Voucher!',
  [BRAND_WOWCHER]: 'View Wowcher!',
};
export const GET_WOWCHER_ERROR = {
  [BRAND_LIVINGSOCIAL]: 'An error occurred while generating your voucher',
  [BRAND_WOWCHER]: 'An error occurred while generating your wowcher',
};
export const EXTENDED = 'Voucher extended!';
export const VOUCHER_PROTECTED = {
  Available: 'Voucher protected!',
  Expired: 'Voucher protected!',
  Redeemed: 'Voucher protected!',
  Refunded: 'Voucher refunded!',
};
export const VOUCHER_PROTECTED_DROPDOWN_TEXT = {
  Expired:
    'There are fewer than 7 days before the voucher expires. Refund is no longer available.',
  Redeemed: 'Refund is not available for redeemed voucher.',
};
export const VOUCHER_PROTECTED_DROPDOWN_HEADING = 'Voucher Protection';
export const VOUCHER_PROTECTED_IMMEDIATE_REFUND_TEXT = 'Immediate:';
export const VOUCHER_PROTECTED_CASH_REFUND_TIME_TEXT = 'Up to 5 working days:';
export const VOUCHER_PROTECTED_DROPDOWN_SUB_HEADING = {
  [BRAND_LIVINGSOCIAL]: 'Get your voucher refunded',
  [BRAND_WOWCHER]: 'Get your Wowcher refunded',
};
export const VOUCHER_PROTECTED_CUSTOMER_SERVICE =
  'Please contact our Customer Service Team:';
export const CUSTOMER_SERVICE_WHATSAPP_BUTTON_TEXT = 'WHATSAPP US';
export const CUSTOMER_SERVICE_EMAIL_BUTTON_TEXT = 'EMAIL US';
export const CREDIT_REFUND_BUTTON_TEXT = {
  [BRAND_LIVINGSOCIAL]: 'credit refund',
  [BRAND_WOWCHER]: 'Credit Refund',
};
export const OR_TEXT = 'or';
export const CASH_REFUND_LINK_TEXT = {
  [BRAND_LIVINGSOCIAL]: 'cash refund',
  [BRAND_WOWCHER]: 'Cash Refund',
};
export const CHOOSE_REFUND_REASON_TEXT = 'Choose your refund reason:';
export const VOUCHER_REFUND_SUCCESS_MESSAGE =
  'Voucher refunded successfully. Your refund will be processed shortly.';
export const VOUCHER_REFUND_CONFIRMATION_TEXT =
  'Are you sure you want to refund your voucher?';
export const REFUND_BUTTON_TEXT = 'Refund';
export const CANCEL_REFUND_TEXT = 'No';
export const CASH_REFUND_DISCLAMER_TEXT =
  '*Refund will be issued to the original payment method. Mixed cash and credit payment will be refunded to those accounts accordingly.';
export const VOUCHER_REFUND_ERROR_TEXT = 'Please select an option to continue';
export const MAIL_TO_CUSTOMER_SERVICE_LINK = 'mailto:cs@dealhelp.co.uk';
export const VIEW_PROGRESS = 'VIEW PROGRESS!';
export const READY_TO_REDEEM = 'Ready to redeem';
export const REDEEMED = 'Redeemed';
export const DELIVERY_DATE = 'Delivery on or before';
export const DELIVERED_ON = 'Delivered on';
export const DELIVERY = 'Delivery';
export const SPEEDY_DELIVERY = 'via speedy delivery';
export const BOOKED = 'Booked';
export const PROCESSING_ORDER = 'Processing Order';
export const DELIVERED = 'Delivered';
export const DISPATCHED = 'Dispatched';
export const VOUCHER_CODE = {
  [BRAND_LIVINGSOCIAL]: 'Voucher Code',
  [BRAND_WOWCHER]: 'Wowcher Code',
};
export const NEED_HELP = 'Need help?';
export const CLICK_REDEEM_IT = "Click 'Redeem it!'";
export const ORDER_NUMBER = 'Order Number';
export const ORDER_NUMBER_SHORT = 'Order No.';
export const GIFTED_TO = 'Gifted to';
export const GIFT_WRAPPED = 'Gift wrapped';
export const GIFT_WRAPPED_DECRIPTION =
  'Please enter your gift message and recipient address upon redemption';
export const TRACK_MY_ORDER = 'Track my order!';
export const TRACKING_STEP_ONE_TEXT = (warehouseDeal) =>
  `You have redeemed your order and it is now on its way to ${
    warehouseDeal ? BRAND_NAME[process.env.NEXT_PUBLIC_BRAND] : `the merchant`
  } for processing.`;
export const TRACKING_STEP_TWO_TEXT = (warehouseDeal, businessName) =>
  `${
    warehouseDeal ? BRAND_NAME[process.env.NEXT_PUBLIC_BRAND] : businessName
  } has received your order${
    warehouseDeal ? ` on behalf of ${businessName}` : ''
  }! It is now in progress.`;
export const TRACKING_STEP_THREE_TEXT = ({
  warehouseDeal,
  businessName,
  courierName,
  showTrackingToCustomer,
}) =>
  `${
    warehouseDeal ? BRAND_NAME[process.env.NEXT_PUBLIC_BRAND] : businessName
  } has dispatched your order${
    warehouseDeal ? ` on behalf of ${businessName}` : ''
  }${courierName && showTrackingToCustomer ? ` via ${courierName}` : ''}.`;
export const TRACKING_STEP_FOUR_TEXT =
  'Your order has been delivered to your chosen delivery address!';
export const INTERNATIONAL_TRACKING_NOTES =
  'Please note, tracking references may not be recognised on this international delivery until the order enters the UK.';
export const DOMESTIC_TRACKING_NOTES =
  'Please allow up to 48 hours for this tracking to become active.';
export const TRACKING_REF_IS = 'Your tracking reference is:';
export const STATUS_TIP = ({ estimatedDeliveryDate, businessName }) =>
  `${
    BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
  } are unable to provide order progress updates for this deal. If your order has not arrived by ${formatShortDate(
    estimatedDeliveryDate,
  )} please contact ${businessName} using the details on your ${
    PREFORMATTED_STRINGS.brandTerminology.wowcher_or_voucher
  }.`;

// Returns Portal Text Constants
export const NEED_RETURN_ORDER = 'Need to contact the merchant?';
export const START_RETURN = 'Start a Return';
export const TRACK_RETURN = 'Track your return';
export const SELECT_REFUND_OPTION = 'Select your Refund Option:';
export const SELECTED_REFUND_OPTION = 'Selected Refund Option:';
export const RETURN_PORTAL_SUBTEXT_ONE =
  'Refunds will be processed once your item has been received and inspected by the merchant. We aim to refund you within 5 days of receiving the returned item.';
export const RETURN_PORTAL_SUBTEXT_TWO =
  'Please note: Return items must be in original resaleable condition. It is not possible to offer refunds on digital or hygiene products where protective seals have been removed; on perishable goods; or on personalised products, unless faulty. The maximum refund for postage will be equivalent to the standard cost, regardless of any premium shipping options chosen during purchase.';
export const RETURN_PORTAL_SUBTEXT_THREE = `If you are returning a refurbished Apple product, please ensure you have deactivated the iCloud account. Failure to do so will mean the item is not returned in its original resaleable condition.`;
export const RETURN_CREDIT = 'Credit';
export const RETURN_CASH = 'Cash';
export const RETURN_CREDIT_HEADING = 'Wallet credit - Supercharge Your Refund!';
export const CHOOSE_WALLET_CREDIT = 'Choose Wallet Credit for an ';
export const SWEET_BONUS = '- a sweet bonus';
export const CREDIT_VALIDITY_TEXT = 'Credit is valid for 3 months';

export const RETURN_CASH_HEADING =
  'Refund will be issued to the original payment method';
export const VIEW_FULL_POLICY = 'View Full Policy';

export const PROBLEM_WAREHOUSE_INFO_ONE =
  'If you have redeemed your code and have an issue with your order please visit our ';
export const PROBLEM_WAREHOUSE_INFO_TWO = 'for further advice.';
export const HELP_CENTRE = 'help center';
export const PROBLEM_NON_WAREHOUSE_INFO_ONE =
  'If you have an issue with your order please contact:';
export const PROBLEM_AT = 'at';
export const WHEN_DELIVERY = 'When will I receive my delivery?';
export const WHEN_DELIVERY_DESCRIPTION = {
  [BRAND_LIVINGSOCIAL]:
    'Please ensure you have allowed the estimated delivery date to pass. The estimated delivery date can be found above. If the estimated delivery date has passed please contact the merchant directly. Please make sure to include your full name, email address and Voucher code.',
  [BRAND_WOWCHER]:
    'Please ensure you have allowed the estimated delivery date to pass. The estimated delivery date can be found above. If the estimated delivery date has passed please contact the merchant directly. Please make sure to include your full name, email address and Wowcher code.',
};
export const RETURN_ITEM = 'Want to return your item?';
export const RESALEABLE_CONDITION =
  'If you wish to return your item please ensure it has not been used and is in a resaleable condition, unless faulty.';
export const PLEASE_NOTE = 'PLEASE NOTE';
export const REFUND_NOT_POSSIBLE =
  'It is not possible to offer refunds or exchanges on digital or hygiene products where protective seals have been removed; on perishable goods; or on personalised products, unless faulty.';
export const TELL_US = 'Tell us the reason for returning the product';
export const BE_IN_TOUCH = 'A dedicated agent will be in touch';
export const BE_IN_TOUCH_DESCRIPTION =
  'A member of our returns team will be in touch to arrange a return. If you indicated you would like an exchange, we will contact you to organise this if possible. Please check your registered email address for a response.';
export const ATTACH_LABEL = 'Attach label and return the item';
export const ATTACH_LABEL_DESCRIPTION =
  'Print off the return label. Insert the first half of the label along with your complete item inside the original packaging. Affix the return address to the outside of the packaging.';
export const ATTACH_LABEL_NOTE =
  'You should use a tracked shipping service, keeping note of any shipping confirmation.';
export const VIEW_RETURNS_LABEL = 'View returns label';
export const MERCHANT_ASSESMENT = 'Merchant Assessment';
export const MERCHANT_ASSESMENT_DESCRIPTION = `The merchant will assess the returned product. If appropriate, the merchant will instruct ${PREFORMATTED_STRINGS.brandTerminology.brand} to refund the order and you will receive a refund confirmation email from ${PREFORMATTED_STRINGS.brandTerminology.brand} within 14 days from the date you returned the item. If you indicated you would like an exchange, the merchant will contact you to arrange this if possible.`;
export const MERCHANT_ASSESMENT_NO_RESPONSE =
  'If you do not receive a response after 14 days, please contact us at';
export const REASON = 'Please select your reason for returning...';
export const PLEASE_PROVIDE_MORE = 'Please provide more information...';
export const EXCHANGE_PLEASE = 'Please contact me if an exchange is available';
export const SUBMIT = 'Submit';
export const MERCHANT_NOTIFIED = 'Merchant has been notified.';
export const CREDIT_REDEEM_LABEL = `Automatically credit my ${
  WALLET_NAME[process.env.NEXT_PUBLIC_BRAND]
} if I do not redeem my voucher before it expires`;
export const CREDIT_REDEEM_DESCRIPTION = `*By clicking yes, you agree that the value of your unredeemed voucher (the "Unredeemed Voucher") will be credited to your ${
  WALLET_NAME[process.env.NEXT_PUBLIC_BRAND]
}. The ${
  WALLET_NAME[process.env.NEXT_PUBLIC_BRAND]
} credit that you receive for your Unredeemed Voucher will be valid for 14 days from the expiry date of the Unredeemed Voucher.`;
export const CREDIT_UNREDEEMS_TITLE = `If you have not redeemed your voucher by the expired date, would you like us to credit the value of your voucher to your ${
  WALLET_NAME[process.env.NEXT_PUBLIC_BRAND]
}?`;
export const DELETE_ACCOUNT_TITLE = `Delete account`;
export const DELETE_ACCOUNT_SUBTITLE = `We're sorry to see you leave`;
export const DELETE_ACCOUNT_BUTTON = 'No, I’ll stay';
export const DELETE_ACCOUNT_LINK = 'Delete';
export const DELETE_ACCOUNT_BULLET_POINTS = `<div>Deleting your account will:</div>
<ul> 
<li>Permanently delete your account and you will no longer be able to login, view or use vouchers.</li>
<li><strong>Remember account deletion is final and permanent. There is no way to restore your account.</strong></li>
</ul>`;
export const DELETE_ACCOUNT_MODAL_TITLE = `Delete account`;
export const DELETE_ACCOUNT_MODAL_TEXT = `
<ul className="modal__list"> 
<li>Your account’s deletion has been initiated.</li>
<li>It can take up to 24 hours to completely delete your account.</li>
<li>Once your account is deleted, you will no longer be able to log in.</li>
</ul>`;
export const YES_PLEASE = 'Yes please!';
export const NO_THANKS = 'No thanks';
export const GIFT_IT = 'Gift it!';
export const GIFTED = 'Gifted!';
export const GIFT_PACK = 'Gift Pack!';
export const GIFT_PACK_SENT = 'Gift Pack Sent!';
export const SEND_AS_E_GIFT = 'Send voucher as E-gift';
export const SEND_GIFT_PACK = 'Send gift pack';
export const YOUR_NAME = 'Your Name';
export const GIFTEE_NAME = 'Giftee name';
export const GIFTEE_EMAIL = 'Recipient email required to send E-voucher';
export const GIFTEE_ADDRESS = 'Giftee postcode';
export const YOUR_MESSAGE = 'Your message';
export const CHARS_REMAINING = 'characters remaining...';
export const VOUCHER_PACK_MESSAGE = (giftType) =>
  `Your gift pack will be sent to the above address within ${
    giftType === GIFTING_TYPE.NEXT_DAY_GIFT_PACK ? '1' : '3'
  } standard working days. You can enter your own address if you’d like to give your gift in person!`;
export const VOUCHER_PACK_SENT =
  'Your gift pack is being sent to the above address.';
export const VOUCHER_DOWNLOAD_MESSAGE = 'Gift sent to your inbox successfully.';
export const VOUCHER_EVOUCHER_MESSAGE =
  'Message sent to recipient successfully.';
export const VOUCHER_GIFTPACK_MESSAGE =
  'Success! Your gift will be sent to address provided.';
export const GIFT_EMAIL_SEND_DATE = 'Gift email will be sent on';
export const EGIFT_DESCRITPION_ONE =
  'The e-gift includes a description and the highlights of the voucher along with redemption instructions.';
export const EGIFT_DESCRITPION_TWO =
  'Download the e-gift and print it to give it in person on the day or email it now and include a personalised message!';
export const SEND_ME_EGIFT = 'Send me e-gift';
export const SEND_THEM_EGIFT = 'Send recipient e-gift';
export const OR = 'OR';
export const SENT_TO_SELF_SUCCESS = {
  [BRAND_LIVINGSOCIAL]: 'gift sent to inbox successfully',
  [BRAND_WOWCHER]: 'Gift sent to inbox successfully',
};
export const SENT_TO_RECIPIENT_SUCCESS = {
  [BRAND_LIVINGSOCIAL]: 'message successfully sent to recipient',
  [BRAND_WOWCHER]: 'Message successfully sent to recipient',
};

// SUBSCRIBE DIALOG
export const SUBSCRIBE_LEGAL_TEXT = {
  livingsocial: `By clicking GO TO DEALS! or Sign Up With Google you agree to receive daily emails featuring local service, shopping and travel offers from LivingSocial.
  You can unsubscribe via the link in each email or login to use the preference centre to change your preferences. Alternatively, press ESC or refresh the page to go directly to the site. View our Privacy &amp; Cookie
  Policy&nbsp;
  <a
    href='${
      LINKS_GLOBAL_WOWCHER[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
        process.env.NEXT_PUBLIC_SITE || 'wowcher'
      ]
    }/page/privacypolicy'
    target="_blank"
    rel="noreferrer noopener"
  >
    here.</a>`,
  livingsocialie: `By clicking GO TO DEALS! or Sign Up With Google you agree to receive daily emails featuring local service, shopping and travel offers from LivingSocial.
  You can unsubscribe via the link in each email or login to use the preference centre to change your preferences. Alternatively, press ESC or refresh the page to go directly to the site. View our Privacy &amp; Cookie
  Policy&nbsp;
  <a
    href='${
      LINKS_GLOBAL_WOWCHER[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
        process.env.NEXT_PUBLIC_SITE || 'wowcher'
      ]
    }/page/privacypolicy-ie'
    target="_blank"
    rel="noreferrer noopener"
  >
    here.</a>`,
  wowcher: `By clicking GO TO DEALS! or Sign Up With Google you agree to receive daily emails featuring local service, shopping and travel offers from Wowcher.
  You can unsubscribe via the link in each email or login to use the preference centre to change your preferences. Alternatively, press ESC or refresh the page to go directly to the site.  View our Privacy &amp; Cookie
  Policy&nbsp;
  <a
    href='${
      LINKS_GLOBAL_WOWCHER[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
        process.env.NEXT_PUBLIC_SITE || 'wowcher'
      ]
    }/page/privacypolicy'
    target="_blank"
    rel="noreferrer noopener"
  >
    here.</a>`,
};

export const TOASTRMSG = 'An unexpected error occured. Please try again later.';
export const TOASTRMSGINVALIDEMAIL =
  'Invalid Email. Please enter a valid email address';
export const AND = 'AND';
export const CLOSE = 'Close';
export const SAVE_EIGHTY_WHERE_YOU_LIVE =
  'Save up to 80% on deals where you live';
export const SKIP = 'Skip';
export const GO_TO_DEALS = 'go to deals';
export const ALL_CAT_PAGE = 'All ##word## deals';
export const BASED_ON_YOUR_PURCHASE = 'Deals you might have missed';

// DEALS
export const FROM = 'From';
export const VIEW_AVAILABILITY = 'View Availability';
export const OPTIONS = 'options';
export const CHOOSE_YOUR_OPTIONS = 'Choose your options';
export const QUICK_BUY = 'Quick Buy';
export const CHOOSE_YOUR_QUANTITY = 'choose your quantity on checkout';
export const BUY = 'Buy';
export const BUY_EXCL = 'BUY!';
export const CLAIM = 'CLAIM';
export const BUYNOW = 'Buy now';
export const BUYNOW_EXCL = 'BUY NOW!';
export const PLEASE_CHOOSE = 'Please choose';
export const PLEASE_CHOOSE_ONE_OPTION = 'Please choose one of the options';
export const PLEASE_CHOOSE_ANOTHER_OPTION = 'Please choose another option';
export const PLEASE_CHOOSE_YOUR_SIZE = 'Please choose your size:';
export const PLEASE_CHOOSE_YOUR_OPTIONS = 'Please choose Your Options:';
export const VIEW = 'View';
export const POSTAGE_AND_PACKAGING_SHORT = 'P&P';
export const POSTAGE_AND_PACKAGING_CLICK_AND_COLLECT =
  'Click & Collect for FREE';
export const TIMER_PRICE_DROP_HEADING = 'Flash sale until';
export const TIMER_DEAL_ENDS_SOON_HEADING = 'Deal Expiring Soon';
export const SOCIAL_CUE_TOTAL_BOUGTH = '##COUNT## Bought';
export const SOCIAL_CUE_TOTAL_REMAINING = '##COUNT## Remaining';
export const LIMITED_AVAILABILITY = 'Limited availability';
export const REMAINING_TEXT = 'remaining';
export const BOUGHT_TEXT = 'bought';
export const NEW_DEAL_TODAY = 'New deal today';
export const NEW_DEAL = 'New deal';
export const TODAY = 'today';
export const SOLD_OUT = 'SOLD OUT';
export const AUTHENTIC = {
  'living-social':
    'The authenticity of this product has been verified by Living Social',
  wowcher: 'The authenticity of this product has been verified by Wowcher',
};
export const AVAILABILITY = 'Availability';
export const HIGHLIGHTS = 'Highlights';
export const PRODUCT_FEATURES = 'Product Features';
export const PRODUCT_SPECIFICATIONS = 'Product Specifications';
export const FULL_DETAILS = 'Full Details';
export const DEALS_TO_CHOOSE = 'Experiences to Choose From';
export const FINE_PRINT = 'The Fine Print';
export const HOW_TO_REDEEM = 'How To Redeem';
export const SEE_MORE = 'See more';
export const SEE_LESS = 'See less';
export const ADMIN_FEE_TERM = 'Additional admin fee applies';

export const REFINE = 'Refine';
export const REFINED_RESULTS = 'Refined Results';
export const EXPANDER_MORE = 'Read more';
export const EXPANDER_LESS = 'Read less';
export const EXPANDER_MORE_MOBILE = 'More';
export const EXPANDER_LESS_MOBILE = 'Less';
export const GO_TO_DEAL = 'Go to ##DEAL## deal';
export const NO_SEARCH_RESULTS_FOR = 'There were no search results for ';
export const NO_SEARCH_RESULTS_INSTAED = "Here are today's top deals instead:";
export const NO_SEARCH_RESULTS =
  "There were no search results for <span class='search-results__term'>'##TEXT##'</span>. Here are today's top deals instead:";
export const NO_FILTER_RESULTS =
  'There were no results for your selected filters. Please broaden your search and try again.';
export const ALL_DEALS_LOADED = 'All deals loaded!';

export const SAVE_UP_TO = 'Save up to';
export const FILTER_SORT_BY = 'Sort by';
export const FILTER_CATEGORIES = 'Categories';
export const FILTER_PRICE = 'Filter by price';
export const FILTER_PRICE_SHORT = 'Price';
export const UP_TO = 'up to';
export const APPLY = 'Apply';
export const CLEAR = 'Clear';
export const DONE = 'Done';
export const FEATURED_DEAL_TITLE = 'Featured Deal!';
export const IMAGE_NOT_FOUND_ALT = 'Image not found';
export const SHARE = 'Share';
export const COPY = 'COPY';
export const SHARE_ON_FACEBOOK = 'Share on Facebook';
export const SHARE_ON_TWITTER = 'Share on Twitter';
export const SHARE_ON_WHATSAPP = 'Share on WhatsApp';
export const SHARE_BY_EMAIL = 'Share by email';
export const SHARE_BY_MOBILE = 'Share by mobile';
export const LOCATION = 'Location';
export const PAYPAL_CREDIT_ALT = 'PayPal credit logo';
export const PAYPAL_CREDIT_TEXT =
  '0% interest for 4 months available on this purchase.';
export const PAYPAL_CREDIT_REPRESENTATIVE_TEXT = `Representative Example:
<ul style="padding-left:15px;"> 
<li> Representative 23.9% APR (variable).</li>
<li> Purchase rate 23.9% p.a. (variable).</li>
<li> Assumed credit limit £1200.</li>
</ul>`;
export const PAYPAL_CREDIT_LINK_TEXT = 'Click for details.';
export const PAYPAL_PAY_LATER = 'Pay Later';
export const STAR_RATING = 'star rating';
export const ANON_USER = 'Anonymous User';
export const PERCT_OFF = '% off';
export const CALL_TO_BOOK = 'Call to book';
export const PREVIEW_DEAL = 'This is a deal preview!';
export const NO_PREVIEW_DEAL = 'Deal preview not found!';

export const DEAL_WILL_BE_ADVERTISED =
  'This deal will be advertised on site until';
export const PRICE_MAY_CHANGE = 'Price is subject to change.';
export const PREORDER_BUY_AND_REDEEM = (estimatedDelivery) =>
  `Buy & redeem this deal now and get delivery from ${estimatedDelivery}`;

// Amenities section
export const AMENITIES = 'Amenities';
export const SHOW_MORE = 'Show more';
export const SHOW_LESS = 'Show less';

// SUBCAT REDIRECTED
export const SUBCAT_REDIRECTED = (categoryName) =>
  `Sorry there are no live deals for that category. Here are similar deals in ${categoryName}.`;

// SOLD OUT BANNER
export const BANNER_TITLE = 'Out of stock!';
export const BANNER_TEXT =
  'We will notify you when this product becomes available.';
export const BANNER_TEXT_DAY = 'Check back tomorrow as more will be available.';
export const BANNER_TEXT_WEEK =
  'Check back next week as more will be available.';
export const BANNER_BUTTON = 'notify me';
export const BANNER_SHOW_LINK = 'See Deal Detail +';
export const BANNER_HIDE_LINK = 'Hide deal detail -';
export const BANNER_PLACEHOLDER = 'Email Address';

// MERCHANDISING MODULES
export const SHOP_NOW = 'SHOP NOW';
export const SHOP_ALL_DEALS = (moduleName) => `Shop all ${moduleName} deals`;

// TYPE
export const TYPE_CATEGORY_DEALS = 'CATEGORY_DEALS';
export const TYPE_CATEGORY_DEAL = 'CATEGORY_DEAL';

// PROMO
export const PROMO_ADD = 'Add promo code';
export const PROMO_APPLY = 'APPLY';
export const PROMO_REMOVE = 'REMOVE';
export const PROMO_PLACEHOLDER = 'PROMO CODE';
export const PROMO_NOT_AUTHENTICATED =
  'Please register or log in to apply promo code';

// SUMMARY
export const SUMMARY_SUBTOTAL = 'Subtotal:';
export const SUMMARY_ADMIN_FEE = 'Administration fee:';
export const SUMMARY_GIFT_PACK_EXPRESS = 'Gift Pack - Next Day Delivery:';
export const SUMMARY_GIFT_PACK_STANDARD = 'Gift Pack - Standard Delivery:';
export const SUMMARY_GIFT_PACK = 'Gift Pack including postage:';
export const SUMMARY_GIFT_WRAP = 'Gift wrap:';
export const SUMMARY_POST_PACKAGING = 'Post & Packaging:';
export const SUMMARY_POSTAGE_CALCULATED = 'How is postage calculated?';
export const SUMMARY_POSTAGE_CHARGE = (value) =>
  `We will only charge for postage on the ${value} items with the most expensive postage. The rest comes free!`;
export const SUMMARY_PROMO_DISCOUNT = (discount) =>
  `Promo code (${discount}%):`;
export const SUMMARY_TOTAL_TO_PAY = 'Total to pay:';
export const SUMMARY_TOTAL_TO_PAY_VIP = 'Total to pay + VIP:';
export const SUMMARY_CONGRATULATIONS = 'Congratulations, ';
export const SUMMARY_YOU_SAVED = 'you have saved:';
export const SUMMARY_BALANCE_OF = 'Balance of ';
export const SUMMARY_MERCHANT = ' to be paid to merchant(s)';
export const SUMMARY_POSTAGE_DISCOUNT =
  'Add more items to receive a discount on postage';
export const SUMMARY_CREDIT_APPLIED = 'Credit applied:';
export const SUMMARY_TOTAL_PAY_TODAY = 'Total to pay today:';
export const VIP_WELCOME_DISCOUNT = 'VIP welcome discount:';
export const VIP_DOUBLE_DISCOUNT = 'Discount!';

// REDEEM CODE
export const REDEEM_CODE_ORDER_NO = 'Order Number:';
export const REDEEM_CODE_WOWCHER_CODE = (value) => `${value} Code:`;
export const REDEEM_CODE_ORDER_ERROR = `Please enter a valid ${
  isWowcherBrand() ? 'Order Number' : 'order number'
}`;
export const REDEEM_CODE_ORDER_REQUIRED = `${
  isWowcherBrand() ? 'Order Number' : 'order number'
} is required`;
export const REDEEM_CODE_WOWCHER_CODE_REQUIRED = (value) =>
  `${value} ${isWowcherBrand() ? 'Code' : 'code'}  is required`;
export const REDEEM_CODE_WOWCHER_CODE_ERROR = (value) =>
  `Please enter a valid ${value} code`;
export const REDEEM_CODE_CONFIRM_MERCHANT =
  'I confirm that I have not redeemed this code with the merchant';
export const REDEEM_CODE_BTN_CONFIRM = 'Confirm';
export const REDEEM_CODE_BTN_CANCEL = 'Cancel';
export const REDEEM_CODE_BTN_DONE = 'DONE';
export const REDEEM_CODE_UNUSED_WOWCHER = (value) =>
  `Redeem an unused ${value} code towards this purchase`;
export const DEALS_SAVINGS = (value) =>
  `Congratulations, as a VIP you have saved an extra ${value} on this deal`;

// CREDIT CLAIM
export const CREDIT_CLAIM_REASON_ARIA_LABEL = 'Reason for refund';
export const CREDIT_CLAIM_REASON_SELECT_DEFAULT = 'Please select a reason...';
export const CREDIT_CLAIM_DEFAULT_SORRY_MESSAGE =
  'Sorry there was a problem please try again later.';
export const CREDIT_CLAIM_FULL_CREDIT_REFUND =
  'Please confirm that you would like us to proceed with this request. If you have changed your mind, just press Cancel to return to the main page.';
export const CREDIT_CLAIM_NON_REFUNDABLE = (value) =>
  `Note: Part of your purchase was made with ${value} of non-refundable credit.`;
export const CREDIT_CLAIM_TOWARDS_DEAL = (preString, value) =>
  `Your ${preString} has been redeemed towards your purchase to the value of <strong>${value}</strong>`;
export const CREDIT_CLAIM_NOT_TOWARDS_DEAL = (preString, value) =>
  `Your ${preString} has been redeemed for <strong>${value}</strong> of credit. This is now available in your account.`;
export const CREDIT_CLAIM_NOT_TOWARDS_DEAL_RETURN = (preString) =>
  `To return to the My ${preString} page, just click Done below.`;
export const CREDIT_CLAIM_ERROR_CURRENCIES = (preString) =>
  `The ${preString} and deal currencies don't match.`;

// CREDIT
export const CREDIT_MSG_PURCHASE = (value) =>
  `Use my ${value} credit for this purchase`;
export const EXP_CREDIT_MSG_PURCHASE = (value) =>
  `Use ${value} of my credit for this purchase`;
export const CREDIT_MSG_LARGE = (totalWallet, amountInWallet) =>
  `Use ${totalWallet} of my ${amountInWallet} credit for this purchase`;
export const CREDIT_MSG_ZERO = (value) =>
  `Use my ${value} credit for this purchase`;
export const CREDIT_MSG_CANNOT = (value) =>
  `(${value} marketing credit cannot be used`;
export const CREDIT_MSG_ALLOWED = ' with a promo code)';
export const CREDIT_MSG_NOT_ALLOWED = ' for this purchase)';

// SOCIAL CUES
export const SOCIAL_CUES_MAY_INCREASE =
  'This price may increase if you buy later';
export const WOWCHER_VIP_SOCIAL_CUE = "YOU'RE A";
export const WOWCHER_VIP_SOCIAL_CUE_ALT = 'vip logo';
export const BESTSELLER = 'BEST SELLING OPTION*';
export const BESTSELLER_EXPLAIN =
  '*This is the best selling option within this deal.';
export const CUE_VIP_EXCLUSIVE = 'VIP EXCLUSIVE!';

// Lightbox
export const GOOGLE_SIGN_UP_ALT = 'Sign up with Google';
export const SUBSCRIPTION_EMAIL_IS_REQUIRED = 'Subscription email is required';
export const SUBSCRIPTION_LOCATION_IS_REQUIRED =
  'Choose your location is required';
export const CHOOSE_YOUR_LOCATION = 'Choose your location';
export const KICKBOX_FAILED_VALIDATION = 'Please enter a valid email address';
export const PLEASE_WAIT = 'Please wait...';
export const SUBSCRIPTION_SUCCESSFUL = 'Email registered';
export const SUBSCRIPTION_FAIL = `Email wasn't registered`;
export const SUBSCRIPTION_ERROR = `Error on user subscription.`;
export const DELIVERABLE = 'DELIVERABLE';
export const RISKY = 'RISKY';
export const UNDELIVERABLE = 'UNDELIVERABLE';
export const UNKNOWN = 'UNKNOWN';

// Error pages
export const TEXT_NOT_FOUND_SERIOUSLY =
  '##STATUSCODE## - seriously, so embarrassing.';
export const TEXT_NOT_FOUND_LOOKS_LIKE =
  'Looks like the page you are looking for is temporarily unavailable.';
export const TEXT_NOT_FOUND_CONTINUE_SHOPPING = 'Continue shopping';
export const PAGE_NOT_FOUND = 'Page not found';
export const OR_SEARCH_INSTEAD = 'Or search our site here instead';
export const PAGE_ERROR_TITLE = 'Page not found';

// Default SEO texts
// export const DEFAULT_SEO_TEXT =
//  'Browse our deals and offers and save up to 80%. Where you’re spoiling yourself or treating someone else, you’re sure to find that perfect deal in our sale.';
export const DEFAULT_SEO_TEXT = '';
export const DEFAULT_SEO_TEXT_CATEGORY =
  'Browse our ##CATEGORY## deals and offers and save up to 80%. Whether you’re spoiling yourself or treating someone else, you’re sure to find that perfect deal in our ##CATEGORY## sale.';
export const DEFAULT_SEO_TEXT_SUBCATEGORY =
  'Browse our ##SUBCATEGORY## deals and offers and save up to 80%. Whether you’re spoiling yourself or treating someone else, you’re sure to find that perfect deal in our ##SUBCATEGORY## sale.';

// Infinite scroll
export const HIDE_FOOTER = 'Hide footer';
export const SHOW_FOOTER = 'Show footer';
export const PAGE_TEXT = 'Page';
export const BACK_TO_TOP = 'Back to top';

// Invasive Health
export const IHEALTH_TITLE = `${
  BRAND_NAME[process.env.NEXT_PUBLIC_BRAND]
} Wellbeing`;
export const IHEALTH_TITLE_NOHURRY = "There's no hurry";
export const IHEALTH_TEXT_NOHURRY =
  "Our health offers are here for longer so you've got time to make the right decision.";
export const IHEALTH_TITLE_TIMETOTHINK = 'Time to think';
export const IHEALTH_TEXT_TIMETOTHINK =
  'Your voucher is valid for at least six months to give you enough time to consider wether you wish to proceed with the treatment.';
export const IHEALTH_TITLE_NOTSURE = 'Not sure?';
export const IHEALTH_TEXT_NOTSURE =
  "If you change your mind after buying this deal or find you are medically unsuitable, we'll give you a refund.";
export const IHEALTH_TITLE_SAFEHANDS = "You're in safe hands";
export const IHEALTH_TEXT_SAFEHANDS =
  'We only use qualified merchants to ensure you get the best possible care and service. Before redeeming your voucher with a merchant, take the time to do some research to ensure you are happy with them and the products and services that they offer.';

// VAT Text
export const VAT_TEXT_ONE = 'This deal is brought to you by ';
export const VAT_TEXT_TWO = 'VAT number: ';

// passenger details
export const PASSENGER_DETAILS_HEADING = 'Passenger Details';
export const PASSENGER_DETAILS_NOTE =
  'Please add the passenger details as they appear on your passports.';
export const PASSENGER_DETAILS_REQUIRED = '*required field';
export const PASSENGER_DETAILS_LABEL_CONTACTNO = 'Contact Phone Number';
export const PASSENGER_DETAILS_PLACEHOLDER_CONTACTNO = 'Phone number*';

export const PASSENGER_HEADING = (value) => `Passenger ${value}`;
export const PASSENGER_SELECT_TITLES = [
  { name: 'Title*', value: '' },
  { name: 'Mr', value: 'Mr' },
  { name: 'Mrs', value: 'Mrs' },
  { name: 'Ms', value: 'Ms' },
  { name: 'Miss', value: 'Miss' },
];
export const PASSENGER_PLACEHOLDER_FNAME = 'First name(s)*';
export const PASSENGER_PLACEHOLDER_LNAME = 'Last Name*';
export const PASSENGER_LABEL_DOB = 'Date of Birth';
export const PASSENGER_SELECT_DAYS = 'Day*';
export const PASSENGER_SELECT_MONTHS = 'Month*';
export const PASSENGER_PLACEHOLDER_YEARS = 'Year* (YYYY)';

// forgot password form
export const TITLE_FORGOT_PASSWORD = {
  'living-social': 'LivingSocial | Forgot Password',
  wowcher: 'Wowcher | Forgot Password',
};
export const FORGOT_PASSWORD_TITLE = 'Forgot Password';
export const FORGOT_PASSWORD_PLACEHOLDER = 'Email';
export const FORGOT_PASSWORD_BTN = 'REQUEST RESET PASSWORD LINK ';
export const FORGOT_PASSWORD_ERROR =
  'An unexpected error occured, please try again later.';
export const FORGOT_PASSWORD_RESET_LINK = () =>
  `If you have an account, you will receive an email to reset your password in the next minute or so! If you haven’t received a link, please check the email that you have entered.`;

// reset password form
export const TITLE_RESET_PASSWORD = {
  'living-social': 'LivingSocial | Reset Password',
  wowcher: 'Wowcher | Reset Password',
};
// reset password form
export const TITLE_COMPLIANCE = {
  'living-social': 'LivingSocial | Refund Form',
  wowcher: 'Wowcher | Refund Form',
};
export const COMPLIANCE_TITLE = 'Refund Form';
export const RESET_PASSWORD_TITLE = 'Reset Password';
export const RESET_PASSWORD_PLACEHOLDER_PASSWORD = 'Reset Password';
export const RESET_PASSWORD_PLACEHOLDER_CONFIRM = PASSWORDS_SHOULD_MATCH;
export const RESET_PASSWORD_BTN = 'RESET PASSWORD AND LOGIN';
export const RESET_PASSWORD_ERROR_MIN = 'Enter at least 6 characters';
export const SORT_CODE_ERROR = 'Sort code must be 6 numbers';
export const ACCOUNT_NUMBER_ERROR_MAX = 'Account number must be 8 numbers';
export const RESET_PASSWORD_ERROR_MATCH = PASSWORDS_SHOULD_MATCH;
export const RESET_PASSWORD_ERROR =
  'An unexpected error occured, please try again later.';
export const REFUND_ERROR =
  'An unexpected error occured, please logout and log back in';
// Activate password modal & confirm password modal
export const TITLE_ACTIVATE_PASSWORD = {
  'living-social': 'LivingSocial | Activate Password',
  wowcher: 'Wowcher | Activate Password',
};
export const ACTIVATE_PASSWORD_TITLE = 'Activate your account';
export const ACTIVATE_PASSWORD_EXPRESS_CHECKOUT_TITLE =
  'Activate your account now!';
export const ACTIVATE_ACCOUNT = 'Activate account';
export const ACTIVATE_PASSWORD_PLACEHOLDER_PASSWORD = 'Password';
export const ACTIVATE_PASSWORD_PLACEHOLDER_PASSWORD_CONFIRM =
  'Confirm Password';
export const ACTIVATE_PASSWORD_BTN = 'LOGIN';
export const ACTIVATE_PASSWORD_SUB_TITLE =
  'By activating your account you can:';
export const ACTIVATE_EXPRESS_CHECKOUT_ACCOUNT =
  'Activate your account to view and manage your order';
export const ACTIVATE_PASSWORD_BULLET_ONE = 'Redeem your voucher';
export const ACTIVATE_PASSWORD_BULLET_TWO = 'Track orders';
export const ACTIVATE_EXPRESS_CHECKOUT_PASSWORD_BULLET_TWO = 'Track your order';
export const ACTIVATE_PASSWORD_BULLET_THREE =
  'Complete your e-gift and gift pack orders';
export const ACTIVATE_EXPRESS_CHECKOUT_PASSWORD_BULLET_THREE =
  'Complete your gifting (if you purchased an e-gift or gift pack)';
export const ACTIVATE_PASSWORD_BULLET_FOUR = 'Claim refund credit';
export const CONFIRM_PASSWORD_TITLE = 'Please confirm your email';
export const CONFIRM_PASSWORD_TITLE_JUST_PURCHASED = `Thanks for your order! We hope you enjoy your ${PREFORMATTED_STRINGS.brandTerminology.wowcher_or_voucher}!`;
export const CONFIRM_PASSWORD_INFO =
  'We have sent you an email to activate your account and redeem your voucher NOW!';
export const CONFIRM_PASSWORD_INFO_PS =
  'P.S. This might take a few minutes to get delivered to your inbox!';

// booking
export const CYF_BANNER_ALT =
  'Choose your flight times and airline with instant booking';
export const ALTERNATIVE_FLIGHTS = 'ALTERNATIVE FLIGHTS';
export const ALTERNATIVE_FLIGHTS_HEADING = 'Choose An Alternative Flight';
export const NO_ALTERNATIVE_FLIGHTS =
  'Sorry there are no alternative flights available.';
export const NO_FLIGHTS_FOR_SELECTION =
  'Sorry, there are no flights for the times selected.';
export const BOOKING_NO_RESULTS =
  'Sorry, there are no results for these selections.';
export const INBOUND_RANGE_TIME = 'Inbound Departure Time';
export const OUTBOUND_RANGE_TIME = 'Outbound Departure Time';

export const BOOKING_STEP_ONE = 'Step 1: Choose a date';
export const BOOKING_STEP_TWO = 'Step 2: Choose your stay';
export const BOOKING_STEP_THREE = 'Step 3: Choose payment option';

export const BCOR_STEP_ONE = 'Choose number of nights';
export const BCOR_STEP_ONE_SINGLE_NIGHT = 'Number of nights';
export const BCOR_STEP_TWO = 'Choose your option';
export const BCOR_GIFT_MESSAGE =
  'Date Flexibility: Gift recipients choose their dates when redeeming their voucher.';

export const CM_STEP_ONE = 'Choose Check-In and Check-Out Dates';
export const CM_GIFT_STEP_ONE = 'Choose number of nights';
export const CM_STEP_TWO = 'Choose your stay';
export const CM_STEP_THREE = 'Choose payment option';

export const CLEAR_SELCTION = 'Clear Selection';
export const CLEAR_BUTTON = 'Clear';
export const USE_ORIGINAL_FLIGHT = 'use original';
export const SELECT_FLIGHT = 'select flight';
export const PRICES_SHOWN_LOWEST =
  'prices shown refer to the lowest priced product';
export const CHOOSE_ARRIVAL = 'Please choose your arrival date';
export const NO_PRODUCTS =
  'Sorry, no products available for selected dates. Please try other dates.';
export const NO_PRODUCTS_FOR_NIGHTS =
  'Sorry, no products are available for your chosen nights. Please try a different selection.';
export const FLIGHT_ERROR =
  "Sorry, there's been a problem getting the flights.";
export const DEPOSIT_AVAILABLE = (price) =>
  `Deposit payment FROM ${price}. Balance to be paid directly to the merchant`;
export const UNAVAILABLE = 'Unavailable';
export const GENERIC_FAILURE = 'Something went wrong. Please try again.';
export const DATE_AVAILABLE = 'Available';
export const BOOKABLE_AT_REDEMPTION =
  'Choose your dates for travel on redemption';
export const CM_CHOOSE_DATES =
  'Please select your check-in and check-out dates.';
export const CM_CHOOSE_CHECKOUT_DATE = 'Please select your check-out date.';
export const CM_CHOOSE_PRODUCT = 'Please select a product.';
export const CYF_BOOKING_STEP_ONE = 'Step 1: Number of Passengers';
export const CYF_BOOKING_STEP_TWO = 'Step 2: Your Departure Airport';
export const CYF_BOOKING_STEP_THREE = 'Step 3: Number of Nights';
export const CYF_BOOKING_STEP_FOUR = 'Step 4: Choose Arrival Date';
export const CYF_BOOKING_STEP_FIVE = 'Step 5: Choose your Stay';
export const CYF_BOOKING_STEP_SIX = 'Step 6: Included Flight';
export const CYF_BOOKING_STEP_SIX_A = 'Step 6: Your Chosen Flight';

// video
export const PLAY_VIDEO = 'Play video';
export const PAUSE_VIDEO = 'Pause video';

// vouchers
export const VIEW_HISTORY = {
  livingsocial: 'view history',
  livingsocialie: 'view history',
  wowcher: 'View history',
  wowcherie: 'View history',
};
export const VIEW_YOUR_WOWCHERS = {
  livingsocial: 'view your vouchers',
  livingsocialie: 'view your vouchers',
  wowcher: 'View your Wowchers',
  wowcherie: 'View your Wowchers',
};

// account options
export const ACCOUNT_OPTIONS_LOWER = [
  {
    name: 'Need help?',
    value: MINI_MENU_NEED_HELP[process.env.NEXT_PUBLIC_SITE],
  },
];
export const ACCOUNT_OPTIONS_HI = (value) => `Hi ${value}`;

// Wowcher status
export const WOWCHER_STATUS_LIST_SHARED = [
  {
    desc: 'The voucher has been redeemed',
    title: 'Redeemed',
  },
  {
    desc:
      'Expect travel documentation from Merchant within 3 working days from booking',
    title: 'Booked',
  },
  {
    desc: 'A credit has been requested',
    title: 'Credit Requested',
  },
  {
    desc: 'Redeemed for credit or towards another deal',
    title: 'Request Processed',
  },
  {
    desc: 'A return has been requested',
    title: 'Return Requested',
  },
  {
    desc: 'The item is on the way back to the merchant',
    title: 'Return in Transit',
  },
  {
    desc: 'The item has been returned to the merchant',
    title: 'Returned to the Merchant',
  },
  {
    desc: 'The voucher has been refunded',
    title: 'Refunded',
  },
  {
    desc: 'The voucher expiry date has passed',
    title: 'Expired',
  },
];

export const WOWCHER_STATUS_LIST = {
  [BRAND_LIVINGSOCIAL]: [
    {
      desc: 'Print this voucher and use it to redeem your deal!',
      title: 'Ready to Redeem',
    },
    ...WOWCHER_STATUS_LIST_SHARED,
  ],
  [BRAND_WOWCHER]: [
    {
      desc: 'Print this Wowcher and use it to redeem your deal!',
      title: 'Ready to Redeem',
    },
    ...WOWCHER_STATUS_LIST_SHARED,
  ],
};

export const WOWCHER_STATUS_TITLE = {
  livingsocial: 'Voucher Status',
  livingsocialie: 'Voucher Status',
  wowcher: 'Wowcher Status',
  wowcherie: 'Wowcher Status',
};

// Club Wowcher
export const CLUB_WOWCHER = 'Club Wowcher';
export const WHAT_IS_CLUB_WOWCHER = 'What is Club Wowcher?';
export const ABOUT_ME = 'About Me';
export const STATS = 'Stats';
export const MEMBERSHIP_LEVEL = 'Membership Level';
export const LIFETIME_POINTS = 'Lifetime membership points';
export const CURRENT_BALANCE = 'Current balance';
export const POINTS_EARNED_TO_DATE = 'Points earned to date';
export const LOADING_ERROR =
  'An unexpected error occured, please try again later.';
export const REWARDS_LEGAL_TEXT =
  'Any points awarded for purchases will be credited 30 days after the date of your purchase unless you refund within these 30 days. Points are valid for 6 months from the date of issue and will expire if they are not converted into Wowcher Wallet Credits within this time.';
export const EXCHANGE_NOW = 'Exchange now!';
export const EXCHANGE_TITLE = 'Exchange your points for Wowcher credit';
export const EDIT_FORM_UPDATE_BUTTON_TEXT = 'update';
export const PASSOWRD_FORM_BUTTON_TEXT = 'change password';

// Wowcher Wallet
export const WOWCHER_WALLET = (value) => `${value} Wallet`;
export const READ_WALLET_DETAILS = 'Read about wallet details';
export const HIDE_WALLET_DETAILS = 'Hide wallet details';
export const VIEW_TERMS = 'View terms';
export const WALLET_INFO_LINE_FIRST = ` is where you'll find credit you've been awarded or any credit refunded to your account.`;
export const WALLET_INFO_LINE_SECOND =
  "Just select the deal you want to buy and tick the 'Apply available Wallet Credit' option on the payment page. The credit amount will be deducted from your final payment.";
export const WALLET_INFO_LINE_THIRD =
  'If you were awarded credit by email, then the credit will expire on the date set out in the email. If there was no date, then it will expire at midnight 3 months after the date of the original message.';
export const WALLET_INFO_LINE_FOURTH = (value) =>
  `If you use your credit as part payment towards a ${value}, and then you decided to cancel your purchase; then you will forfeit the credit amount and we will only refund the difference that you paid.`;
export const NO_WOWCHER_WALLET_CREDIT = (brandName) =>
  `You currently have no ${brandName} Wallet credit.`;
export const WALLET_AVAILABLE = 'Available credit:';
export const WALLET_TH_AMOUNT = 'Amount';
export const WALLET_TH_AVAILABLE = 'Available';
export const WALLET_TH_DATE = 'Date';
export const WALLET_TH_TRANSACTION = 'Transaction';

// Claim Credits
export const CLAIM_CREDIT = 'Claim credit';
export const CLAIM_CREDIT_MESSAGE = (value) =>
  `Instead of redeeming your ${value} with the merchant you may redeem it towards another deal or redeem for ${
    isWowcherBrand() ? 'Wallet Credit below' : 'wallet credit below'
  }.`;
export const MORE_INFORMATION = 'For more information please visit';
export const WOWCHER_WALLET_CREDIT = 'Wowcher Wallet Credits.';
export const LIVING_SOCIAL_WALLET_CREDIT = 'LivingSocial wallet credits.';

// Payment Errors
export const ERROR_BANK_UNRESPONSIVE = `We tried to take payment but didn’t get a response from your Bank. Try a different card or use Paypal and then try again.`;
export const ERROR_BILLING_ADDRESS = `Oops, it looks like you’ve typed your billing address incorrectly. Please try again!`;
export const ERROR_CARD_AMEX_DINERS = `We don’t accept American Express / Diners Card so try again using another card type, or use Paypal!`;
export const ERROR_CARD_DECLINED = `Sorry, your card has been declined. Please try another card or payment method.`;
export const ERROR_CARD_DECLINED_BANK = `Sorry, your card has been declined by your bank. Please try another card or payment method.`;
export const ERROR_CARD_DECLINED_DETAILS = `Sorry, your card has been declined. Please check that you entered the details correctly or try another card/payment method.`;
export const ERROR_CARD_EXPIRED = `Oh no, this card has expired! Add a new card or why not try PayPal!`;
export const ERROR_CARD_EXPIRED_NEW_CARD = `Oh no, this card has expired! Add a new card and let’s get this payment sorted.`;
export const ERROR_CARD_INCORRECT_CVV = `Oops, it looks like you’ve typed your CVV incorrectly. Please try again!`;
export const ERROR_CARD_INCORRECT_DATE = `Oops, it looks like you’ve typed your expiry date incorrectly. Please try again!`;
export const ERROR_CARD_INCORRECT_NUMBER = `Oops, it looks like you’ve typed your card number incorrectly. Please try again!`;
export const ERROR_CARD_INCORRECT_SECURITY_CODE = `Oops, it looks like you’ve typed your security code incorrectly. Please try again!`;
export const ERROR_CARD_INCORRECT_ADDRESS = `Oops, it looks like you’ve typed your address incorrectly. Please try again!`;
export const ERROR_PAYPAL_REJECTION = `Sorry, PayPal rejected that transaction. Please check your account or try an alternative payment method.`;
export const ERROR_USING_PAYPAL_AND_WALLET =
  'Your entire order uses wallet credit. For security reasons please follow the card payment flow below';
export const ERROR_ISSUER_AUTHENTICATION =
  'Your card issuer cannot authenticate this card. Please select another card or form of payment to complete your purchase.';
export const ERROR_AUTHENTICATION =
  'There was a problem with authentication. Please select another payment method.';

export const POPULATE_CARD_OR_SWITCH = `popOrSwitch`;
export const PAYPAL_OR_SWITCH = `paypalOrSwitch`;
export const POPULATE_EMPTY_CARD_OR_SWITCH = `popEmptyOrSwitch`;
export const SAVED_CARD = `savedCard`;
export const POPULATE_CARD_INPUT = `cardInputPop`;

export const FETCH_DEALS_ERROR = 'FETCH DEALS ERROR';
export const ERROR_FETCHING_DEALS = 'Error fetching deals from the API.';

export const FETCH_FACETED_NAVIGATION_ERROR = 'FETCH FACETED NAVIGATION ERROR';
export const ERROR_FETCHING_FACETED_NAVIGATION =
  'Error fetching faceted navigation from the API.';

// Hyperlocal SEO
export const HYPER_LOCAL_SEO_TITLE = (seoFriendlyName, area) =>
  `${seoFriendlyName} near ${area}`;
export const HYPER_LOCAL_SEO_META_TITLE = (seoFriendlyName) =>
  `${seoFriendlyName} - Deals of up to 80% off`;

export const RECENTLY_VIEWED_TITLE = 'Recently Viewed Deals';
export const RECOMMENDED_FOR_YOU_TITLE = 'Recommended For You';
export const BUY_AGAIN_TITLE = 'Buy again';
export const EARLY_BIRD_DEALS = 'Early bird deals';
export const BUY_AGAIN_DEALS_FLAG = 'BUY_AGAIN_DEALS_FLAG';

export const EMPTY_WISHLIST_MESSAGE_ONE = 'No deals found!';
export const EMPTY_WISHLIST_MESSAGE_TWO =
  'Start adding deals to your wish list';
export const EMPTY_VOUCHERS_MESSAGE = {
  [BRAND_LIVINGSOCIAL]: 'You currently do not have any vouchers',
  [BRAND_WOWCHER]: 'You currently do not have any wowchers',
};

export const ALL_VOUCHERS_LOADED = (count) =>
  `All ${count} ${
    count === 1
      ? PREFORMATTED_STRINGS.brandTerminology.Wowcher_or_voucher
      : PREFORMATTED_STRINGS.brandTerminology.Wowchers_or_vouchers
  } loaded!`;

// Reserve Button and Deposit Payment
export const RESERVE_CHECKOUT = 'RESERVE & CHECKOUT';
export const BUTTON_RESERVE_NOW = 'RESERVE NOW ';
export const DEPOSIT_MESSAGE =
  'Deposit payment <b>FROM %price</b>. Balance to be paid directly to merchant.';
export const DEPOSITS = 'Deposits ';

export const TRIP_ADVISOR_ALT = 'Trip Advisor logo';
export const REVIEWS_IO_ALT = 'Reviews IO logo';
export const PASSENGER = 'Passenger';

// Buy it again
export const BUTTON_BUY_IT_AGAIN = 'Buy again!';

// Gift finder
export const GIFT_RECIPIENT = "Who's the lucky one?";
export const BUDGET_ICONS = 'How much do you love them?';
export const GIFT_PERSONALITY = 'Tell us a bit more about them…';
export const GIFT_BANNER_TITLE = 'Gift finder';
export const GIFT_BANNER_SUBTEXT = 'Find the perfect gift for your loved ones';
export const CLEAR_ALL = 'Clear All';
export const GIFT_NO_RESULTS =
  "Oh no! We couldn't find the perfect gift for your loved ones...Please ";
export const CLEAR_YOUR_FILTERS = 'clear your filters';
export const TRY_AGAIN = ' and try again!';

// Honeypot & kickbox
export const LEAVE_BLANK = 'Please leave blank';
export const DID_YOU_MEAN = 'Did you mean?';

// Gifting
export const GIFTING = 'GIFTING?';
export const GIFTING_AT_WOWCHER = {
  'living-social': 'gifting explained',
  wowcher: 'GIFTING EXPLAINED',
};

export const GIFTING_EXPLAINED_DISCOUNTS = {
  'living-social': 'prices and discounts are hidden',
  wowcher: 'PRICES AND DISCOUNTS ARE HIDDEN',
};

export const GIFTING_EXPLAINED_GIFTPACK = {
  'living-social': 'personalised gift pack with highlights & images',
  wowcher: 'PERSONALISED GIFT PACK WITH HIGHLIGHTS & IMAGES',
};

export const GIFTING_EXPLAINED_DELIVERY = {
  livingsocial: 'multiple delivery options available - direct to recipient',
  livingsocialie: 'delivered direct to recipient',
  wowcher: 'MULTIPLE DELIVERY OPTIONS AVAILABLE - DIRECT TO RECIPIENT',
  wowcherie: 'DELIVERED DIRECT TO RECIPIENT',
};

export const GIFTING_EXPLAINED_PDF = {
  'living-social': 'pdf available for same day gifting',
  wowcher: 'PDF AVAILABLE FOR SAME DAY GIFTING',
};

export const GIFT_PACK_POP_UP_ALT =
  '1. Sleek pink presentation wallet to PDF, 2.Personalised message, 3. Highlights and images from your chosen gift, 4. Wowcher price and discount hidden, 5. Delivery within 3 working days';
export const GIFT_WRAP_POP_UP_ALT =
  '1. Comes in a gift wrapped bag, 2.Personalised message, 3. Wowcher price and discount hidden, 4. Sent direct to recipient';

// Merchant
export const MERCHANT_ERROR_MESSAGE =
  'There seems to be issues with your url, please check the url supplied or contact us for assistance.';

export const REDEEM_WOWCHER_QR_CODE_ALT = 'Redeem wowcher qr code';
export const REDEEM_WOWCHER_INFO_ALT =
  '1. Scan the QR code, 2.Install the wowcher app, 3.Redeem your voucher and enjoy.';
// Quick Buy
export const ERROR_MESSAGE =
  "We couldn't contact the payment provider right now. Please pay using Visa or Mastercard.";
export const NO_EXPRESS_BUY =
  'Requested deal cannot be purchased via Express Buy.';
export const TRY_CHECKOUT = ' Please try our checkout service instead.';
export const TRY_DIFFERENT = ' Sorry please try a different payment method.';
export const GENERIC_ERROR =
  'An error occured, your purchase has not been completed.';
export const PROGRESS_MESSAGE_WAIT = 'Please wait...';
export const PROGRESS_MESSAGE_PAY = 'Making payment...';
export const PROGRESS_MESSAGE_SUCCESS = 'Purchase Successful';
export const VOUCHER_EXTENDED = 'Voucher Extended';
export const PROGRESS_MESSAGE_FAILED = 'Payment failed.';
export const AVAILABLE_PAYMENT_METHODS = `The available payment methods are: Credit Card (we accept both Mastercard & Visa), PayPal, Google Pay${
  isClearpayEnabled ? ', Clearpay' : ''
}${isKlarnaEnabled ? `, Apple Pay and Klarna.` : ` and Apple Pay.`}`;

// Gifts
export const GIFTS = 'Gifts';
export const GIFTSTITLE =
  'Gifts & Presents - Online Gifts, Experiences & Ideas - Wowcher';
export const GIFTSCANONICALURL = 'https://www.wowcher.co.uk/gifts';
export const GIFTS_DESCRIPTION =
  'If you’re looking for a great gift for a loved one, then we’ve got everything you need right here on Wowcher! Whether it’s their birthday, Christmas or you just want to say ‘well done’, we’ve got the perfect gifts for you.';
export const GIFTS_BANNER_DESCRIPTION =
  'Our great gift finder tool will help you find the perfect gifts that are tailored to who your recipient is, and their personalities and hobbies';
export const FIND_GIFT_NOW_BUTTON = 'Find your gift now!';
export const FIND_YOUR_PERFECT_GIFT = 'FIND YOUR PERFECT GIFT';
export const GIFTS_SUBTEXT = 'Find the perfect gift for your loved one.';
export const CLAIM_GIFT_LOGIN = 'login / register';
// unsubscribe
export const UNSUBSCRIBE_TITLE = 'Unsubscribe';
export const UNSUBSCRIBE_TITLE_ALL = 'Unsubscribe all';
export const UNSUBSCRIBE_ERROR =
  'No unsubscribe token found. Please click the unsubscribe link in the email to try again.';

// 2nd Checkout
export const PAGE_TITLE_BENEFITS = {
  'living-social': 'LivingSocial | Benefits',
  wowcher: 'Wowcher | Benefits',
};

// 2nd Checkout Progress
export const ORDER_CONFIRMED = 'Order Confirmed';
export const BENEFITS = {
  'living-social': 'Livingsocial Benefits',
  wowcher: 'Wowcher Benefits',
};
export const DEALS = 'Deals';
export const REDEEM_VOUCHERS = {
  'living-social': 'My Vouchers',
  wowcher: 'My Wowchers',
};
export const SECOND_CHECKOUT_CASHBACK_TEXT =
  'Click on Skip to Redeem your Vouchers';
export const CONTRACT_DISCLAMER_TEXT =
  'I understand that, once I redeem a voucher, a separate contract is formed between the merchant and me.';
// 2nd Checkout Wowcher Benefits
export const PURCHASE_COMPLETE_DISCLAMER =
  'Your voucher purchase is complete. This purchase is a separate and optional transaction.';
export const BECOME_SOCIAL_CUE = 'Become a ';
export const VIP = 'VIP';
export const VIP_BENEFITS =
  'Get access to bigger savings, endless rewards, and more!';
export const BECOME_VIP_PRICE = '-months VIP membership for';
export const SEE_BENEFITS = 'See the benefits';
export const EXTEND_VOUCHER_VALIDITY = 'Extend Voucher Validity';
export const YOUR_VOUCHERS = 'Your Voucher(s)';
export const PROTECT_MY_VOUCHER = 'Protect My Voucher';
export const GIVE_YOURSELF_TIME =
  'Give yourself an extra minute to redeem your voucher!';
export const GIVE_YOURSELF_PEACE =
  'Give yourself peace of mind by extending your refundable period';

// 2nd Checkout Are you sure Popup on toggle off
export const ARE_YOU_SURE = `Are you sure you don't want to `;
export const NOT_TODAY = 'Not today';
export const NEED_MORE_TIME = "Need more time?\n We've got you covered";
export const SITUATIONS_CHANGE = 'Situations change, we get it!';

export const NO_DEALS_TEXT =
  'Oops, we cannot find any complementary deal to show you';
export const VVE_BENEFITS_BULLET_LIST = [
  'Give yourself more time to redeem',
  'Extend voucher validity by up to 3 months',
];
export const PMV_BENEFITS_BULLET_LIST = [
  'No quibble refund, for any reason',
  'Self-service refund in cash or credit, you choose',
  'Extend your right to refund for a longer period',
];
export const PMV_BENEFITS_DESCRIPTION =
  'Protect My Voucher allows you to refund your unredeemed voucher until seven days before expiry.';

// 2nd Checkout Payment Success Popup
export const PURCHASED_SUCCESSFULLY = 'Purchased Successfully';
export const OOPS_PAYMENT = 'Oops! Payment Failed';
export const NOT_PROCESS = ' We could not process your payment';
export const TRY_AGAIN_PLEASE =
  'Try again. Please select a different payment method below or pay using another card';

// Map View
export const MAP = 'Map';
export const SHOW_ON_MAP = 'Show on Map';

export const MARKETING_CREDIT_INFO = (isLivingSocialie) =>
  `Please note - Promotional Marketing Credit is limited to 10% (up to ${
    isLivingSocialie ? '€' : '£'
  }5) per eligible basket item.`;

// RAF - Refer A Friend
export const INVITE_FRIENDS = 'Invite Friends';
export const REFER_YOUR_FRIENDS = 'Refer your friends';
export const CREDIT_TWO_HUNDRED = `${isIESite() ? '€' : '£'}200 credit`;
export const REFERRAL_CODE_DUMMY = 'XXXXX-XXXXX';
export const GENERATE_LINK = 'Generate Unique Link';
export const FAQ = 'Frequently Asked Questions';
export const TRACKING_INFO = 'Tracking Info';
export const SEND_UNIQUE_LINK = (
  referralCredit,
) => `Send your unique link to your friends, family and colleagues and you will both recieve ${
  isIESite() ? '€' : '£'
}${referralCredit} Wowcher Wallet 
  credit after they make their first purchase! `;
export const HOW_IT_WORKS = 'How it Works';

export const RAF_TNC = `<h5>To see more of our Terms and Conditions, read <a href="${
  ECOMMERCE_TERMS[process.env.NEXT_PUBLIC_SITE]
}" target="_blank">here</a>.</h5>`;
export const REFER_UNIQUE_LINK = 'This is your unique referral link:';
export const RAF_STEP_ONE =
  'Sign in and choose Refer a Friend in the menu to see your unique referral link.';
export const RAF_STEP_TWO =
  'Send your unique referral link to a friend, colleague or family member, using social media, email or WhatsApp.';
export const RAF_STEP_THREE = `Once your friend has clicked the link, signed up & made a purchase*, you will receive ${
  isIESite() ? '€' : '£'
}10 credit in your Wowcher Wallet.<br><h5>*Credit is awarded after the 14 day refund window</h5>`;
export const WELCOME = 'Welcome';
export const REFEREE_REGISTERED_BANNER_HEADING = 'Thanks for using Wowcher!';
export const REFEREE_REGISTERED_BANNER_TEXT =
  'Since you’re an existing Wowcher customer, you cannot be referred. But you can refer your friends!';
export const TO_THE_CLUB = 'to the club!';
export const CREDIT_ADDED_TEXT = (referralCredit) =>
  `You have now had ${isIESite() ? '€' : '£'}${referralCredit} 
  credit added to your wowcher wallet. Make a purchase for your friend to receive their 
  ${isIESite() ? '€' : '£'}${referralCredit}.`;
export const START_SHOPPING = 'Start Shopping!';
export const START_REFERRING = 'Click here to refer';
export const RAF_ACCOUNT_CREATION_INFO = `Please create an account before making your purchase. Once you create an account, your referral credit will be added to your account to use towards your first purchase!`;
export const RAF_CANCEL_INFO =
  'Any balance shown for cancelled referrals represents the credit not received in the event your friend refunded their order';
export const RAF_PENDING_INFO = `Any balance shown for pending referrals represents the credit you are due after your friends' 14 day refund window passes (without a refund)`;
export const REFERRALS = 'Referrals';
export const REFERRAL_LINKS_OPENED = '# Links Opened';
export const REFERRAL_LINKS_SHARED = '# Links Shared';

// Live Availablity Of CM (Channel Manager) Deals
export const CM_MY_VOUCHER_MESSAGE =
  'Live availability: Redeem your voucher NOW to secure your booking.';
export const REDEEM_YOUR_VOUCHER = 'Redeem your wowcher';
export const SECURE_BOOKING = 'to secure your booking';
export const PURCHASE_INSTANTLY = 'instantly after purchase';
export const DATE_FLEXIBILITY =
  'Date flexibility: Gift recipients choose their dates when redeeming their voucher.';
export const AUTO_REDEEM_MESSAGE =
  'Congratulations! Your booking is confirmed. Check your email for all the details.';
/** Editorial page content **/

export const EDITORIAL_TEAM = [
  {
    alt: "Katrina's image",
    content:
      "Katrina is the Head of Content and Production at Wowcher, overseeing the creation of all deals and blog posts across the National, Local and Travel sectors. She joined the company in 2019 as a junior writer on National and over the past five years has worked her way up through the business, garnering extensive deal knowledge and experience along the way. Add that to a previous role as a charity shop manager, and it's safe to say that she is well versed in what a great deal looks like! Outside of Wowcher, Katrina is a pop culture enthusiast and is always writing about the latest music releases and celebrity news stories. In her spare time, you will either find her in a theatre enjoying a West End musical or travelling across the country, watching one of her favourite boybands on tour.",
    email: 'katrina.rees@wowcher.co.uk',
    image: 'https://resources.wowcher.co.uk/images/biopic01.JPG',
    linkedin: 'https://www.linkedin.com/in/katrina-rees-7a4b7b106',
    title: 'Katrina Rees - Head of Content',
  },
  {
    alt: "Kristy's image",
    content:
      "Kirsty is our Head of National Content. With 4 years' experience writing opinion pieces, political articles, product reviews and personal essays, she excels whenever she has a pen in her hand and idea in her head. She also enjoys writing poetry and short stories too, and has even written a children's book. She now looks after the National team of content writers here at Wowcher and oversees the creation of every deal, blog and guide page that goes live. In her spare time, you'll find Kirsty bundled up with a big fantasy novel and an even bigger coffee, humming whatever pop song or musical theatre tune she's fixated on this week!",
    email: 'kirsty.griffiths@wowcher.co.uk',
    image: 'https://resources.wowcher.co.uk/images/biopic02.JPG',
    linkedin: 'https://www.linkedin.com/in/kirsty-griffiths/',
    title: 'Kirsty Griffiths - Head of National Content',
  },
];

export const EDITORIAL_PAGE_HEADING =
  'Wowcher is working with Upfeat to bring you the very best discount codes and offers across 100s of your favourite retailers. Giving you a WOW day is our main goal, and we have an extensive list of valid deals and voucher codes for you to browse through, including fashion, food, and travel retail brand. Here at Wowcher, we are experts at finding a great bargain, which is why all offers, from discount codes to limited-time sales, are checked and validated by our Content team. They’ll even share with you some of their Editor Picks, so you don’t have to miss out on the latest deals!';
export const DISCOUNT_CODE_TEXT = 'You can view all our discount codes here: ';
export const MEET_OUR_WRITERS_HEADING = 'MEET OUR WRITERS';
export const LINKEDIN_TEXT = 'LinkedIn: ';
export const EMAIL_TEXT = 'Email Id: ';
export const NIBBLE_DISCOUNT_CUE = (discount) =>
  `Wow, you’ve saved an extra ${discount}%!`;
export const NIBBLE_TIMER = 'NIBBLE_TIMER';
export const NIBBLE_TRACKING_FLAG = 'NIBBLE_TRACKING_FLAG';
